import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/system';
import { MStatusPlates } from '../MStatusPlates'
import MTable from './MTable';

export type FieldType = 'name' | 'status' | 'report';

export interface TableHeading {
    fieldName: string,
    field: string
    type?: FieldType;
}

interface MStudyTableProps {
  headings: TableHeading[];
  rows: any[];
  onRowClick?: (id: string) => void;
  sx?: SxProps<Theme>;
}

export const MStudyTable = (props: MStudyTableProps) => {
  const { headings, rows, onRowClick } = props;

  const renderCell = (heading: TableHeading, row: any) => {
    switch (heading.type) {
        case 'name':
          return <b>{row[heading.field]}</b>;
        case 'status':
          return <MStatusPlates value={row[heading.field]} />;
        case 'report':
          return row.report ? (
          <Box 
            sx={{ 
              textDecoration: 'underline',
              fontWeight: '600'
            }} 
            onClick={(e:any) => {
              e.stopPropagation();
              window.open(row.report, "_blank");
            }}
          >
            View Final Report
          </Box>
          ) : '—';
        default:
          return row[heading.field];
    }
};

  return (
    <MTable
      headings={headings}
      rows={rows}
      renderCell={renderCell}
      onRowClick={onRowClick}
    />
  );
}

export default MStudyTable;
