import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {SLAStatusesEnum, StudySeveritiesEnum, StudyStatuses, StudyStatusesEnum} from '../api/study';
import { allStudyStatuses } from "../utils/constants";

interface MStatusPlatesProps {
  value: StudyStatuses;
  disableStyling?: boolean
}

interface MSeverityPlatesProps {
  value: StudySeveritiesEnum;
  disableStyling?: boolean
}

interface MSLAStatusPlatesProps {
  value: SLAStatusesEnum;
  disableStyling?: boolean
}

export const MStatusPlates = (props: MStatusPlatesProps) => {
  const { value, disableStyling } = props;

  const defaultStyles = {
    paddingX: 1.5,
    paddingY: 1,
    borderRadius: 1.5,
    display: 'inline-flex',
    '& > p': {
      fontWeight: '700'
    }
  }

  const renderValue = () => {
    switch (value) {
      case StudyStatusesEnum.not_enrolled:
        return {
          label: allStudyStatuses.find(ss => ss.value === StudyStatusesEnum.not_enrolled)?.label,
          sx: {
            ...defaultStyles,
            // border: '1px solid #808080',
            color: 'black',
            padding: 'calc(originalPadding - 2px)',
            background: '#f4f4f4',
            //padding: "1px"
          }
        };
      case StudyStatusesEnum.draft:
        return {
          label: allStudyStatuses.find(ss => ss.value === StudyStatusesEnum.draft)?.label,
          sx: {
            ...defaultStyles,
            border: '1px solid #808080',
            color: '#808080'
          }
        };
      case StudyStatusesEnum.inprogress:
        return {
          label: allStudyStatuses.find(ss => ss.value === StudyStatusesEnum.inprogress)?.label,
          sx: {
            ...defaultStyles,
            background: '#F3F5E8'
          }
        };
      case StudyStatusesEnum.in_review:
        return {
          label: allStudyStatuses.find(ss => ss.value === StudyStatusesEnum.in_review)?.label,
          sx: {
            ...defaultStyles,
            background: '#A3D5FF'
          }
        };
      case StudyStatusesEnum.interpretating:
        return {
          label: allStudyStatuses.find(ss => ss.value === StudyStatusesEnum.interpretating)?.label,
          sx: {
            ...defaultStyles,
            background: '#F3F5E8'
          }
        };
      case StudyStatusesEnum.completed:
        return {
          label: allStudyStatuses.find(ss => ss.value === StudyStatusesEnum.completed)?.label,
          sx: {
            ...defaultStyles,
            background: '#CEE0C7'
          }
        };  
      default:
        return {
          label: '-',
          sx: {
            ...defaultStyles,
            background: 'grey'
          }
        };
    }
  }

  if (!renderValue()) return <></>;
  if (disableStyling) return <>{renderValue()?.label}</>;

  return (
    <Box sx={renderValue().sx}>
      <Typography>{renderValue().label}</Typography>
    </Box>
  );
}

export default MStatusPlates;


export const MSeverityPlates = (props: MSeverityPlatesProps) => {
  const { value, disableStyling } = props;

  const defaultStyles = {
    paddingX: 1.5,
    paddingY: 1,
    borderRadius: 1.5,
    display: 'inline-flex',
    '& > p': {
      fontWeight: '700'
    }
  }

  const renderValue = () => {
    switch (value) {
      case StudySeveritiesEnum.level_1:
        return {
          label: "Severity level: "+ value.toString() + " (test mode)",
          sx: {
            ...defaultStyles,
            border: '1px solid #808080',
            background: '#F3F5E8'
          }
        };
      case StudySeveritiesEnum.level_2:
        return {
          label: "Severity level: "+ value.toString() + " (test mode)",
          sx: {
            ...defaultStyles,
            background: '#F0AD4E'
          }
        };
      case StudySeveritiesEnum.level_3:
        return {
          label: "Severity level: "+ value.toString() + " (test mode)",
          sx: {
            ...defaultStyles,
            background: '#D9534F'
          }
        };
      default:
        return {
          label: 'Severity level: -- (test mode)',
          sx: {
            ...defaultStyles,
            background: '#F3F5E8'
          }
        };
    }
  }

  if (!renderValue()) return <></>;
  if (disableStyling) return <>{renderValue()?.label}</>;

  return (
      <Box sx={renderValue().sx}>
        <Typography>{renderValue().label}</Typography>
      </Box>
  );
}


export const MSLAStatusPlates = (props: MSLAStatusPlatesProps) => {
  const { value, disableStyling } = props;

  const defaultStyles = {
    paddingX: 1.5,
    paddingY: 1,
    borderRadius: 1.5,
    display: 'inline-flex',
    '& > p': {
      fontWeight: '700'
    }
  }

  const renderValue = () => {
    switch (value) {
      case SLAStatusesEnum.in_range:
        return {
          label: "Within SLA",
          sx: {
            ...defaultStyles,
            //border: '1px solid #808080',
            background: '#F3F5E8'
          }
        };
      case SLAStatusesEnum.close_to_violation:
        return {
          label: "SLA Risk",
          sx: {
            ...defaultStyles,
            //border: '1px solid #808080',
            background: '#FFB347'
          }
        };
      case SLAStatusesEnum.violated:
        return {
          label: "Violated",
          sx: {
            ...defaultStyles,
            //border: '1px solid #808080',
            background: '#FF7276'
          }
        };
      default:
        return {
          label: 'Unknown',
          sx: {
            ...defaultStyles,
            background: '#F3F5E8'
          }
        };
    }
  }

  if (!renderValue()) return <></>;
  if (disableStyling) return <>{renderValue()?.label}</>;

  return (
      <Box sx={renderValue().sx}>
        <Typography>{renderValue().label}</Typography>
      </Box>
  );
}

// export MSeverityPlates;
