import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/system';
import { MStatusPlates } from '../MStatusPlates'
import {baseUrls} from "../../routes";
import {Link} from "react-router-dom";
import MTable from './MTable';

export type FieldType = 'id' | 'url' | 'report' | 'date_start';

export interface TableHeading {
    fieldName: string,
    field: string
    type?: FieldType;
}

interface AdminMeasurementsTableProps {
    headings: TableHeading[];
    rows: any[];
    onRowClick?: (id: string) => void;
    sx?: SxProps<Theme>;
}

export const AdminStudiesTable = (props: AdminMeasurementsTableProps) => {
    const { headings, rows, sx, onRowClick } = props;
    const formatMultipleValuesColumn = (i:number,value:string) => {
        return <>
            {i != 0 && <><br/><br/></>}
            {value}
        </>
    }
    const renderCell = (heading: TableHeading, row: any) => {
        switch (heading.field) {
            case "status":
                return <MStatusPlates value={row[heading.field]} />
            case 'id':
                return <Link style={{ fontSize: '14px' }} to={baseUrls.clientsTools+"/study/"+row[heading.field]}>{row[heading.field]}</Link>
            case 'date_start':
                return (row[heading.field] === null) ? null : new Date(row[heading.field]).toLocaleString('en-GB', {  
                    day: '2-digit',  
                    month: '2-digit',  
                    year: 'numeric',  
                    hour: '2-digit',  
                    minute: '2-digit',  
                    hour12: false  
                });
            case 'date_upload':
                return <>{row.measurements.map((measurement:any,i:number) =>
                    formatMultipleValuesColumn(i,measurement.date_upload?
                        new Date(row[heading.field]).toLocaleString('en-GB', {  
                            day: '2-digit',  
                            month: '2-digit',  
                            year: 'numeric',  
                            hour: '2-digit',  
                            minute: '2-digit',  
                            hour12: false  
                        }) : "--"
                    ))}</>
            case 'date_finished':
                return (row[heading.field] === null) ? null : new Date(row[heading.field]).toLocaleString('en-GB', {  
                    day: '2-digit',  
                    month: '2-digit',  
                    year: 'numeric',  
                    hour: '2-digit',  
                    minute: '2-digit',  
                    hour12: false  
                });
            case "device_serial_number":
                return <>{row.measurements.map((measurement:any,i:number) =>
                    formatMultipleValuesColumn(i,measurement.device_serial_number))}</>
            case 'report':
                return row.report ? (
                    <Box
                        sx={{
                            textDecoration: 'underline',
                            fontWeight: '600'
                        }}
                        onClick={(e:any) => {
                            e.stopPropagation();
                            window.open(row.report, "_blank");
                        }}
                    >
                        View Report
                    </Box>
                ) : '—';
            default:
                return row[heading.field];
        }
    };

    return (
        <MTable
            headings={headings} 
            rows={rows}
            renderCell={renderCell}
        />
    );
}

export default AdminStudiesTable;
