import { useState, useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
import PageHeader, { PageHeaderActionsProps } from "../containers/PageHeader";
import Typography from "@mui/material/Typography";
import { addCluster, getClustersList } from "../api/cluster";
import { MModal } from "../components/MModal";
import { MTextField } from "../components/MTextField";
import { MButton } from "../components/MButton";
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Grid, Paper } from "@mui/material";
import UnitsListGrid from "../containers/Grids/UnitsListGrid";
import { getRegion, updateRegion } from "../api/region";
import { MBreadcrumbs } from "../components/MBreadcrumbs";
import { prepareAdminBreadcrumbs } from "../utils/utils";
import { PermissionsE, usePermissions } from "../context/usePermissions";
import {getValueFromLocalStorage} from "../api/auth";
import MSelect from "../components/MSelect";

const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

export const Region = () => {
    const { hasPermission } = usePermissions();
    
    const [isEditRegion, setIsEditRegion] = useState(false);

    const [name, setName] = useState<any>("");
    const [SLAHours, setSLAHours] = useState<any>(0);
    const [SLAWorkDays, setSLAWorkDays] = useState<any>(0);


    const [breadcrumbsData, setBreadCrumbsData] = useState<any>(null);

    const canWrite = hasPermission!(PermissionsE["units.w"]);

    const navigate = useNavigate();
    const { regionId } = useParams();
    // const [regionDetails, setRegionDetails] = useState<any>(null);

    const [busy, setBusy] = useState(false);
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [clustersList, setClustersList] = useState<any[]>([]);

    const [newClusterName, setNewClusterName] = useState("");

    const loadBreadCrumbsData = async() => {
        const { connected_units,role } = await getValueFromLocalStorage('staff_profile');
        let data = prepareAdminBreadcrumbs(role, connected_units, region);
        setBreadCrumbsData(data);
    }

    const fetchRegionDetails = useCallback(async () => {
        if (!hasPermission!(PermissionsE["units.r"])) return;
        if (regionId != null) {
            const regionDetails = await getRegion(regionId);
            //setRegionDetails(regionDetails);
            setName(regionDetails?.name);
            setSLAHours(regionDetails?.sla_hours);
            setSLAWorkDays(regionDetails?.sla_working_days);

            const clustersList = await getClustersList(regionId);
            clustersList.sort((a: any, b: any) => b.departments_number - a.departments_number);
            setClustersList(clustersList);
        }
    }, [regionId])

    useEffect(() => {
        fetchRegionDetails()

    }, [fetchRegionDetails]);

    useEffect(() => {
        loadBreadCrumbsData()
    }, [name])

    const onEntityCreation = async () => {
        if (!canWrite) return;
        setBusy(true);

        let data = {
            "name": newClusterName,
            "region": regionId
        }
        await addCluster(data);
        window.location.reload();

    }

    const submitUpdateRegion = async () => {
        let data = {
            "name": name,
            "sla_hours" : SLAHours,
            "sla_working_days" : SLAWorkDays
        }
        await updateRegion(data, regionId)
        setIsEditRegion(false);
    }

    function renderEditClientButtons() {
        if (!hasPermission!(PermissionsE['units.w_meta'])){
            return <></>
        }

        if (isEditRegion) {
            return <>
                <Button color="error" onClick={() => {
                    setIsEditRegion(false);
                    // getClinicDetail(clinicId);
                }
                }>
                    Cancel
                </Button>
                <Button onClick={() => {
                    submitUpdateRegion();
                    
                    // saveAccountChange();
                }
                }>Save</Button>
            </>
        } else {
            return <>
                <Button onClick={() => setIsEditRegion(true)}>Edit</Button>
            </>
        }
    }    

    const renderModalContent = () => (
        <Box p={3}>
            <Box sx={{ textAlign: 'center' }}>
                <Typography variant="h3">Add new cluster</Typography>
            </Box>
            <Box mt={6} width={320}>

            </Box>
            <Box mt={1.5} width={320}>
                <MTextField
                    label="New Cluster name"
                    onChange={(e) => setNewClusterName(e.target.value)}
                />
            </Box>
            <Box
                mt={8}
                sx={{
                    "& .MuiButton-root:not(:last-child)": { marginRight: 2 },
                    textAlign: 'center'
                }}
            >
                <MButton disabled={busy} onClick={handleClose} color={"inherit"}>Cancel</MButton>
                <MButton busy={busy} onClick={onEntityCreation} color={"primary"}>Confirm</MButton>
            </Box>
        </Box>
    )

    const region = name ? {
        pageName: name ? name : "",
        to: regionId ? regionId : "" 
    } : undefined;

    const pageHeaderActions = [
        canWrite ? {
            onClick: handleOpen,
            name: "Add Cluster"
        } : undefined
    ].filter(Boolean) as PageHeaderActionsProps[];

    const toggleDay = (index: number) => {
        const newBitmask = SLAWorkDays ^ (1 << index);
        setSLAWorkDays(newBitmask);
        console.log("Bitmask Number:", newBitmask);
        console.log("Bit Representation:", newBitmask.toString(2).padStart(7, "0"));
      };
    

    return (
        <>
            <MModal open={open} handleClose={handleClose} width={480} height="auto">
                {renderModalContent()}
            </MModal>
            { breadcrumbsData && (
                <Box mt={2}>
                    <MBreadcrumbs {...breadcrumbsData} />
                </Box>
                )
            }
            <PageHeader
                title={`${(!name ? "??" : name)}`}
                subTitle="region"
                actions={pageHeaderActions}
            />
            <Paper sx={{ minHeight: '70vh', px: 4, py: 5.5 }}>
                    <Grid item xs={12}>
                        <Box mb={2}>
                            <Typography variant="h4" paragraph={true}>Region details {renderEditClientButtons()}</Typography>
                        </Box>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Grid container spacing={8}>
                                    <Grid item xs={6}>
                                        <MTextField
                                            label="Clinic name"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            disabled={!isEditRegion}
                                        />
                                    </Grid>
                                    {
                                        hasPermission!(PermissionsE['units.r_tech']) ? (
                                            <Grid item xs={6}>
                                                <MTextField
                                                    label="Back-office SLA(hours)"
                                                    value={SLAHours}
                                                    type="number"
                                                    inputProps={{ min: 24, max: 100 }}                                            
                                                    onChange={(e) => setSLAHours(e.target.value)}
                                                    disabled={!isEditRegion}
                                                />
                                                <br/>
                                                <br/>
                                                <Typography variant="h6" paragraph={true}>Back-office schedule(working days)</Typography>
                                                
                                                {days.map((day, index) => (
                                                    <>
                                                    <label key={index}>
                                                        <input
                                                        type="checkbox"
                                                        checked={(SLAWorkDays & (1 << index)) !== 0}
                                                        onChange={() => toggleDay(index)}
                                                        disabled={!isEditRegion}
                                                        />
                                                        {day}
                                                    </label>
                                                    <br/>
                                                    </>
                                                ))}
                                    </Grid>
                                        ) : <></>
                                    }
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Box mb={2}>
                                    <Typography variant="h4" paragraph={true}>Units</Typography>
                                </Box>
                                <UnitsListGrid type="cluster" data={clustersList} />
                            </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
}
export default Region;