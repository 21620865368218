import { useNavigate } from "react-router-dom";
import { baseUrls } from "../../routes";
import InboxStudiesTable from "../../components/MTables/InboxStudiesTable";

const tableHeadings = [
    {
        fieldName: 'ID',
        field: 'id'
    },
    {
        fieldName: 'Devices S\N',
        field: 'device_serial_number'
    },
    {
        fieldName: 'Upload date',
        field: 'date_upload'
    },
    {
        fieldName: 'Status',
        field: 'status'
    },
    {
        fieldName: 'SLA Current (Max)',
        field: 'sla_details'
    },
    {
        fieldName: 'SLA Left',
        field: 'sla_left'
    },

    {
        fieldName: 'SLA Status',
        field: 'sla_status'
    }
];

interface InboxGridProps {
    data: any[];
    onReportCellClick?: (url: string) => void;
}

export const InboxGrid = (props: InboxGridProps) => {
    const navigate = useNavigate();

    const { data } = props;

    const rows = data.map((row) => ({
        ...row
    }));

    return (
        <InboxStudiesTable
            headings={tableHeadings}
            rows={rows}
            sx={{ paddingX: 3.5, paddingY: 2.5 }}
            onRowClick={(id) => navigate(`${baseUrls.study}/${id}`)}
        />
    );
};

export default InboxGrid;