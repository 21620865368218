import MPermissionsTable from "../../components/MTables/MPermissionsTable";
import { UserPermissions, UserRolePermissionsEnum } from "../../api/permissions";
import { PermissionsE, usePermissions } from "../../context/usePermissions";
import { TableHeading } from "../../components/MTables/MTable";

const rolePermissionsHeadings = Object.entries(UserRolePermissionsEnum).map(i => ({
    fieldName: i[0],
    field: i[1]
}));

interface IDOrganizationExternalsGridProps {
    data: UserPermissions[];
}

export const MPermissionsGrid = (props: IDOrganizationExternalsGridProps) => {
    const { hasPermission } = usePermissions();
    const canWrite = hasPermission!(PermissionsE["role_perm.w"]);
    
    const { data } = props;

    const tableHeadings = [
        {
            fieldName: 'Key',
            field: 'key'
        },
        {
            fieldName: 'Description',
            field: 'description'
        },
        ...rolePermissionsHeadings,
        canWrite ? {
            fieldName: 'Actions',
            field: 'actions'
        } : null
    ].filter(Boolean) as TableHeading[];

    return (
        <MPermissionsTable
            headings={tableHeadings}
            rows={data}
        />
    );
};

export default MPermissionsGrid;