import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import MModal from "../MModal";
import { Typography, Switch } from "@mui/material";
import { MButton } from "../MButton";
import MTextField from "../MTextField";
import {
    addUnitContact,
    deleteUnitContact,
    updateUnitContact
} from "../../api/unit_contact";
import MTable, { MTableActionsButtons, MTableProps, TableHeading } from "./MTable";
import { PermissionsE, usePermissions } from "../../context/usePermissions";
import { green, red } from '@mui/material/colors';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

interface MUnitContactsTableProps extends MTableProps {
    onRowClick?: (id: string) => void;
    clinicId? : string
}

const BooleanIcon = (value : boolean | any) => {
    return value.value === true ? (
        <CheckCircleIcon style={{ color: green[500] }} />  // Green icon for true
    ) : (
        <CancelIcon style={{ color: red[500] }} />        // Red icon for false
    );
};

const defaultRow = {
    description: '',
    email : '',
    is_critical_notifications : false,
    is_info_notifications : false,
    is_success_notifications : false
}

export const MUnitContactsTable = (props: MUnitContactsTableProps) => {
    const { hasPermission } = usePermissions();
    const canWrite = hasPermission!(PermissionsE["units.w_meta"]);

    const [busy, setBusy] = useState(false);
    const { headings, rows, sx, onRowClick, clinicId} = props;
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [selectedRow,setSelectedRow] = useState<any>(defaultRow);
    const [isEditModal,setIsEditModal] = useState<boolean>(false);


    const renderCell = (heading: TableHeading, row: any) => {
        switch (heading.field) {
            case "is_critical_notifications":
                return <BooleanIcon value={row[heading.field]} />;
            case "is_info_notifications":
                return <BooleanIcon value={row[heading.field]} />;
            case "is_success_notifications":
                return <BooleanIcon value={row[heading.field]} />;
            case "actions":
                return canWrite ? (
                    <MTableActionsButtons 
                        rowData={row}
                        onEdit={() => {
                            setSelectedRow(row);
                            setIsEditModalOpen(true);
                            //setIsEditModalOpen(true)
                        }}
                        onDelete={()=>{
                            setSelectedRow(row);
                            setIsDeleteModalOpen(true);
                        }}
                    />
                ) : undefined;
            default:
                return row[heading.field];
        }
    };

    const handleItemChange = (keyToUpdate: string, newValue: string | number) => {
        let newDict = {...selectedRow};
        newDict[keyToUpdate] = newValue;
        setSelectedRow(newDict);
    };

    const submitUpdateItem = async() => {
        if (!canWrite) return;
        let data = {
            id : selectedRow.id,
            email : selectedRow.email,
            description: selectedRow.description.length > 0 ? selectedRow.description : "-",
            is_critical_notifications : selectedRow.is_critical_notifications,
            is_info_notifications : selectedRow.is_info_notifications,
            is_success_notifications : selectedRow.is_success_notifications,
        }
        await updateUnitContact(selectedRow.id, data, clinicId);
        window.location.reload();
    }

    const submitItem = async () => {
        if (!canWrite) return;
        let data = {
            email : selectedRow.email,
            description: selectedRow.description.length > 0 ? selectedRow.description : "-",
            is_critical_notifications : selectedRow.is_critical_notifications,
            is_info_notifications : selectedRow.is_info_notifications,
            is_success_notifications : selectedRow.is_success_notifications,
            id_unit : clinicId 
        }
        await addUnitContact(data, clinicId);
        window.location.reload();
    }

    const submitDeleteItem = async() => {
        if (!canWrite) return;
        setBusy(true);
        await deleteUnitContact(selectedRow?.id, clinicId);
        window.location.reload();
    }

    const saveHandler = async () => {
        if (!canWrite) return;
        setBusy(true);
        if(selectedRow?.id !== undefined){
            await submitUpdateItem().finally(() => setTimeout(() => setBusy(false), 500));
        } else {
            await submitItem().finally(() => setTimeout(() => setBusy(false), 500));
        }
    }

    return (
        <div>
            <MModal
                open={isDeleteModalOpen}
                handleClose={() => {setIsDeleteModalOpen(false)}}
                width={480}
                height="auto"
            >
                <Box p={3}>
                    <Typography variant="h4" sx={{textAlign: "center", mb: 4}} paragraph={true}>
                        Clinic contact deletion
                    </Typography>

                    <Typography variant="h6" sx={{textAlign: "center", mt: 4}}>
                        Confirm deletion of "{selectedRow.email}"
                    </Typography>


                    <Box sx={{textAlign: "center", mt: 4}}>
                        <MButton disabled={busy} color="error" onClick={() => setIsDeleteModalOpen(false)}>Cancel</MButton>
                        <MButton busy={busy} sx={{ml: 2}} onClick={submitDeleteItem}>Save</MButton>
                    </Box>
                </Box>
            </MModal>
            <MModal
                open={isEditModalOpen}
                handleClose={() => {setIsEditModalOpen(false)}}
                width={480}
                height="auto"
            >
                <Box p={3}>
                    <Typography variant="h4" sx={{textAlign: "center", mb: 4}} paragraph={true}>
                        Clinic Contact
                    </Typography>

                    <MTextField
                        sx={{textAlign: "center", mb: 2}}
                        label="Email"
                        value={selectedRow?.email}
                        onChange={(e) => {handleItemChange("email",e.target.value)}}
                    />
                    <MTextField
                        sx={{textAlign: "center", mb: 2}}
                        label="Description"
                        multiline={true}
                        rows={3}
                        value={selectedRow?.description}
                        onChange={(e) => {handleItemChange("description", e.target.value)}}
                    />
                    
                    <Typography variant="body1" paragraph={true}>Urgent/Important</Typography>
                    <Switch
                        checked={selectedRow?.is_critical_notifications}
                        onChange={(e:any) => {
                            handleItemChange("is_critical_notifications",e.target.checked)
                        }}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                    
                    <Typography variant="body1" paragraph={true}>Status updates</Typography>
                    <Switch
                        checked={selectedRow?.is_info_notifications}
                        onChange={(e:any) => {
                            handleItemChange("is_info_notifications",e.target.checked)
                        }}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                    
                    <Typography variant="body1" paragraph={true}>Reports</Typography>
                    <Switch
                        checked={selectedRow?.is_success_notifications}
                        onChange={(e:any) => {
                            handleItemChange("is_success_notifications",e.target.checked)
                        }}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />


                    <Box sx={{textAlign: "center", mt: 4}}>
                        <MButton disabled={busy} color="error" onClick={() => {setIsEditModalOpen(false)}}>Cancel</MButton>
                        <MButton busy={busy} sx={{ml: 2}} onClick={saveHandler}>Save</MButton>
                    </Box>
                </Box>
            </MModal>

            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width='100%'
                sx={{mb:2}}
            >

                <Typography variant="h4">
                    Clinic Contacts
                </Typography>

                {canWrite ? (
                    <MButton variant="contained" color="primary" onClick={() => {
                        setIsEditModal(false);
                        setIsEditModalOpen(true);
                        setSelectedRow(defaultRow)
                    }}>
                        Add Contact
                    </MButton>
                ) : <></>}

            </Box>
            <MTable 
                headings={headings}
                rows={rows}
                sx={sx}
                renderCell={renderCell}
            />  
        </div>
    );
}

export default MUnitContactsTable;


