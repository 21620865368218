import MAccountsTable from "../../components/MTables/MAccountsTable";
import { TableHeading } from "../../components/MTables/MTable";

interface AccountsGridProps {
    data: any[];
    onUpdate?: (id: string, data: any) => Promise<any>;
    onDelete?: (id: string) => Promise<any>;
    updateLink?: string;
    entity?: 'clinic' | 'cluster' | 'region';
}

export const AccountsGrid = (props: AccountsGridProps) => {
    const { data, onUpdate, onDelete, updateLink, entity } = props;

    const tableHeadings = [
        {
            fieldName: 'Name',
            field: 'name'
        },
        {
            fieldName: 'Email',
            field: 'email'
        },
        {
            fieldName: 'Position',
            field: 'role'
        },
        !entity ? {
            fieldName: 'Access',
            field: 'units'
        } : null,
        {
            fieldName: 'Last online',
            field: 'last_token_obtain'
        },
        onUpdate ? {
            fieldName: 'Is active',
            field: 'is_active'
        } : null,
        onUpdate || onDelete ? {
            fieldName: 'Actions',
            field: 'actions'
        } : null
    ].filter(Boolean) as TableHeading[];

    return (
        <MAccountsTable
            headings={tableHeadings}
            rows={data}
            onUpdate={onUpdate}
            onDelete={onDelete}
            updateLink={updateLink}
            entity={entity}
        />
    );
};

export default AccountsGrid;