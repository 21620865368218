import { useEffect, useRef, useState } from "react"
import moment from "moment";
import { Enrollment, StudyType } from '../api/study';
import { baseUrls } from "../routes";
import { BreadcrumbItem } from "../components/MBreadcrumbs";
import {getValueFromLocalStorage} from "../api/auth";
import {Unit} from "../api/accounts";
import {AccountRolesEnum} from "../containers/AddEditAccountForm/formUtils";

export const emptyValueFormatter = (value: string) => {
  if (!value) return undefined;

  return value;
}

export const emptyStringFormatter = (value: string) => {
  if (!value) return "-";

  return value;
}

export const formatName = (firstName?: string, lastName?: string) => `${firstName} ${lastName}`;

export const formatEnrollment = (enrollment: Enrollment) => {
  if (enrollment.toLowerCase() === 'home') {
    return 'At home';
  }

  if (enrollment.toLowerCase() === 'clinic') {
    return 'In-clinic';
  }

  return '';
}

export const formatStudyType = (studyType: StudyType) => {
  if (studyType === 'holter') {
    return 'Holter';
  }

  if (studyType === 'mct') {
    return 'MCT'
  }

  if (studyType === 'two_week_holter') {
    return "Multi-Holter";
  }

  if (studyType === 'rpm') {
    return 'RPM'
  }

  return '';
}

export const formatDateAPI = (date : Date | null) => date ? moment(date).format("D-M-YYYY") : null;

export const formatDateToShow = (date: Date | string | null) => moment(date).format('DD.MM.YYYY');
export const formatDateTimeToShow = (date: Date | string) => moment(date).format('DD.MM.YYYY HH:mm');

export const formatDateTimeLong = (date: Date | string) => moment(date).format('DD.MM.YYYY HH:mm:ss');

export const formatTimeToShow = (date: Date | string) => moment(date).format('HH:mm');

export const dateFromString = (dateStr:string) =>{return moment(dateStr, 'HH:mm').toDate()}

export const capitalizeFirstLetter = (string: string) => string.charAt(0).toUpperCase() + string.slice(1);

export const isObject = (value: any) => typeof value === 'object' && value !== null;

export const isDraft = (data: any) => isObject(data.draft_data) && data.status === 'draft';

export const isHolter = (value: string) => value.toLowerCase() === 'holter';
export const isMCT = (value: string) => value.toLowerCase() === 'mct';

export const isTwoWeekHolter = (value: string) => value.toLowerCase() === 'two_week_holter';

export const isRPM = (value: string) => value.toLowerCase() === 'rpm';

export const datesDifference = (oldDate: Date) => {
  if(oldDate === null){
    return "Never"
  }

  const formatter = new Intl.RelativeTimeFormat('en', { numeric: 'auto' });
  let currentDate = new Date();

  let diff = currentDate.getTime() - oldDate.getTime()

  if(diff > 30*24*60*60*1000){
    return formatter.format(Math.round(-diff / (30*24*60*60*1000)), 'months');
  } else if(diff > 24*60*60*1000){
    return formatter.format(Math.round(-diff / (24*60*60*1000)), 'days');
  } else if(diff > 60*60*1000){
    return formatter.format(Math.round(-diff / (60*60*1000)), 'hours');
  } else if(diff > 60*1000){
    return formatter.format(Math.round(-diff / (60*1000)), 'minutes');
  } else{
    return formatter.format(Math.round(-diff / 1000), 'seconds');
  }
}

export const formatShortDescription = (major:number,minor:number,build:number,hardware:string) => {
  return major + "." + minor +"." + build + "-"+hardware
}

export const shortenString = (value: string) => {
  if(value.length > 50){
    return value.substring(0,50) + "...";
  }
  return value;
}

export const isValidDate = (d: any) => {
  return d instanceof Date && !isNaN(d as unknown as number);
}

export const pickersMigrationHack = (error?: boolean) => ({
  paddingBottom: '0 !important',

  '& input': {
    padding: '8.5px 14px'
  },

  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: error ? '#d32f2f' : 'rgba(0, 0, 0, 0.23)'
  }
});

export const prepareAdminBreadcrumbs = (
    role: string,
    connected_units:any,
    region?: BreadcrumbItem,
    cluster?: BreadcrumbItem,
    clinic?: BreadcrumbItem) => {

  let breadcrumbs: any[] = [];

  const regions = connected_units.filter((cu: Unit) => isRegion(cu.region || '', cu.cluster || ''));
  const clusters = connected_units.filter((cu: Unit) => isCluster(cu.region || '', cu.cluster || ''));
  const clinics = connected_units.filter((cu: Unit) => isClinic(cu.region || '', cu.cluster || ''));

  let isAdmin = role === AccountRolesEnum['Super Admin'] || role === AccountRolesEnum['IT Admin'];

  let regionId: string = "";
  let clusterId: string = "";
  let clinicId: string = "";

  if (region && (regions.some((item:any) => item.id === region?.to) || isAdmin)) {
    breadcrumbs.push({
      pageName: region.pageName,
      to: `${baseUrls.regions}/${region.to}`
    })
    regionId = region.to;
  }

  console.log("cluster",cluster);
  console.log("clusters",clusters);
  console.log("regionId",regionId);

  if (cluster && (clusters.some((item:any) => item.id === cluster?.to) || cluster.region === regionId || isAdmin)) {
    breadcrumbs.push({
      pageName: cluster.pageName,
      to: `${baseUrls.clusters}/${cluster.to}`
    })
    clusterId = cluster.to;
  }

  if (clinic && ((clinics.some((item:any) => item.id === clinic?.to) ||
      clinic.region === regionId ||
      clinic.cluster === clusterId) ||
      isAdmin)) {

    breadcrumbs.push({
      pageName: clinic.pageName,
      to: `${baseUrls.clinics}/${clinic.to}`
    })
  }

  return {
    homepage: {
      pageName: 'Home',
      to: isAdmin ? baseUrls.regions : baseUrls.mcuHome
    },
    breadcrumbs
  }
}

export const sleep = (ms:number) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const usePrevious = (value: any) => {
    const currentRef = useRef(value)
    const previousRef = useRef()
    if (currentRef.current !== value) {
        previousRef.current = currentRef.current
        currentRef.current = value
    }
    return previousRef.current
}

export const useDebounce = (value: any, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

export const isRegion = (regionId: string, clusterId: string) => !regionId && !clusterId;
export const isCluster = (regionId: string, clusterId: string) => regionId && !clusterId;
export const isClinic = (regionId: string, clusterId: string) => regionId && clusterId;


// export const getDatesBetween = (startDate: Date, endDate: Date): Date[] => {
//   const dates: Date[] = [];


//   // Make a copy of the start date to manipulate
//   let currentDate = new Date(startDate);

//   // Move currentDate to the start of the next day (00:00)
//   currentDate.setHours(24, 0, 0, 0);

//   // Add the start of each new day between startDate and endDate
//   while (currentDate <= endDate) {
//     dates.push(new Date(currentDate));
//     currentDate.setDate(currentDate.getDate() + 1);  // Move to the next day
//     currentDate.setHours(24, 0, 0, 0);  // Reset to 00:00 for the new day
//   }


//   return dates;
// }
export const getDatesBetween = (startDate: Date, endDate: Date): Date[] => {
  const dates: Date[] = [];
  
  // Start with the beginning of startDate's next day
  let currentDate = new Date(startDate);
  currentDate.setHours(0, 0, 0, 0); // Normalize to midnight
  currentDate.setDate(currentDate.getDate() + 1); // Move to next day

  // Loop through each day
  while (currentDate <= endDate) {
    dates.push(new Date(currentDate));
    currentDate = new Date(currentDate); // Create new Date object
    currentDate.setDate(currentDate.getDate() + 1); // Move to next day
  }

  return dates;
};

export const getHoursDifference = (date1: Date, date2: Date): number => {
  const diffMs = Math.abs(date2.getTime() - date1.getTime()); // Difference in milliseconds
  const diffHours = diffMs / (1000 * 60 * 60); // Convert to hours
  return diffHours;
};

export const getIsWorkingDay = (currentDate: Date, bitmask : number) => {
    const jsDay = currentDate.getDay();
    const bitPosition = (jsDay + 6) % 7;
    return (bitmask & (1 << bitPosition)) !== 0;
}

export const getMaxSLADate = (
  dateUpload: Date,
  SLAHours: number,
  SLAWorkingDays: number // Bitmask where Monday=1 (0b0000001), Sunday=64 (0b1000000)
): Date => {
  const resultDate = new Date(dateUpload);
  const originalTime = {
    hours: resultDate.getHours(),
    minutes: resultDate.getMinutes()
  };
  let remainingMinutes = SLAHours * 60;

  while (remainingMinutes > 0) {
    const isWorkingDay = getIsWorkingDay(resultDate,SLAWorkingDays);

    if (isWorkingDay) {
      const currentHours = resultDate.getHours();
      const currentMins = resultDate.getMinutes();
      const minutesPassed = currentHours * 60 + currentMins;
      const minutesRemainingToday = 1440 - minutesPassed;
      
      const minutesToAdd = Math.min(remainingMinutes, minutesRemainingToday);
      
      resultDate.setMinutes(resultDate.getMinutes() + minutesToAdd);
      remainingMinutes -= minutesToAdd;
    }

    if (remainingMinutes > 0) {
      
      // Skip non-working days
      while (true) {
        const nextJsDay = resultDate.getDay();
        const nextBitPosition = (nextJsDay + 6) % 7;
        const isNextWorkingDay = (SLAWorkingDays & (1 << nextBitPosition)) !== 0;
        if (isNextWorkingDay) break;
        resultDate.setDate(resultDate.getDate() + 1);
        resultDate.setHours(0,0);
      }
    }
  }

  return resultDate;
};





export const formatHoursToReadableFormat = (hours : any) => {
  const days = Math.floor(hours / 24);
  const remainingHours = Math.floor(hours % 24);
  const minutes = Math.round((hours % 1) * 60);

  const parts = [];

  if (days > 0) parts.push(`${days}d`);
  if (remainingHours > 0) parts.push(`${remainingHours}h`);
  if (minutes > 0) parts.push(`${minutes}m`);

  return parts.length > 0 ? parts.join(" ") : "0 hours";
}