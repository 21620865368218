import PageHeader, { PageHeaderActionsProps } from "../containers/PageHeader";
import { Paper, Accordion, AccordionSummary, AccordionDetails, Grid, alpha, Chip } from "@mui/material";
import { MTextField } from "../components/MTextField";
import { useCallback, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { listStudiesAdmin } from "../api/study";
import {analysisPlatforms, languages, studiesList, studySeverities, studyStatusesInbox} from "../utils/constants";
import MSelect from "../components/MSelect";
import { getClustersList } from "../api/cluster";
import { getClinicsList } from "../api/clinic";
import MDatePicker from "../components/MDatePicker";
import {formatDateAPI, useDebounce, formatDateToShow, isRegion, isCluster, isClinic} from "../utils/utils";
import { getRegionsList } from "../api/region";
import { usePermissions, PermissionsE } from "../context/usePermissions";

import ExpandMore from "@mui/icons-material/ExpandMore";
import {getValueFromLocalStorage} from "../api/auth";
import {Unit} from "../api/accounts";
import { useSearchParams } from "react-router-dom";
import InboxGrid from "../containers/Grids/InboxGrid";


export const TechnicianInbox = () => {
    const { hasPermission } = usePermissions();
    const [searchParams, setSearchParams] = useSearchParams();

    // Dropdown lists
    const [regions, setRegions] = useState<any>([]);

    const [clusters, setClusters] = useState<any>([]);
    const [clinics, setClinics] = useState<any>([]);


    // Filter options
    const [region, setRegion] = useState<string | undefined>(searchParams.get("region") || undefined);
    const [cluster, setCluster] = useState<string | undefined>(searchParams.get("cluster") || undefined);
    const [clinic, setClinic] = useState<string | undefined>(searchParams.get("clinic") || undefined);


    const [searchTerm, setSearhTerm] = useState<string | undefined>(searchParams.get("searchTerm") || undefined);

    const debouncedSearchTerm = useDebounce(searchTerm, 1000);

    const [studyStatus, setStudyStatus] = useState<string | undefined>(searchParams.get("studyStatus") || undefined);
    const [studyType, setStudyType] = useState<string | undefined>(searchParams.get("studyType") || undefined);
    const [studyLanguage, setStudyLanguage] = useState<string | undefined>(searchParams.get("studyLanguage") || undefined);



    const [analysisPlatform, setAnalysisPlatform] = useState<string | undefined>(searchParams.get("analysisPlatform") || undefined);
    const [severity, setSeverity] = useState<number | undefined>(searchParams.get("severity") ? Number(searchParams.get("severity")) : undefined);


    /* Dates */
    const [dateStartFrom, setDateStartFrom] = useState<Date | null>(
        searchParams.get("dateStartFrom") ? new Date(searchParams.get("dateStartFrom")!) : null
    );
    const [dateStartTo, setDateStartTo] = useState<Date | null>(
        searchParams.get("dateStartTo") ? new Date(searchParams.get("dateStartTo")!) : null
    );
    
    const [dateFinishFrom, setDateFinishFrom] = useState<Date | null>(
        searchParams.get("dateFinishFrom") ? new Date(searchParams.get("dateFinishFrom")!) : null
    );
    const [dateFinishTo, setDateFinishTo] = useState<Date | null>(
        searchParams.get("dateFinishTo") ? new Date(searchParams.get("dateFinishTo")!) : null
    );
    /* end Dates */

    /* Accordion */
    const [filtersExpanded, setFiltersExpanded] = useState<boolean>(false);
    const [expanded, setExpanded] = useState<string | false>(false);

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };
    /* end Accordion */

    const [measurements, setMeasurements] = useState<any[]>([]);
    const [isMeasurementAvailableMore, setIsMeasurementAvailableMore] = useState<boolean>(true);

    const [devices, setDevices] = useState<any[]>([]);
    const [isDevicesAvailableMore, setIsDevicesMore] = useState<boolean>(true);

    const [isDeviceAddOpen, setIsDeviceAddOpen] = useState<boolean>(false);

    useEffect(() => {
        fetchMeasurements();
    }, [debouncedSearchTerm,
        dateFinishFrom,
        dateFinishTo,
        dateStartFrom,
        dateStartTo,
        region,
        cluster,
        clinic,
        studyStatus,
        studyType,
        analysisPlatform,
        severity,
        studyLanguage]);


    useEffect(() => {
        const params: Record<string, string> = {};
    
        if (region) params.region = region;
        if (cluster) params.cluster = cluster;
        if (clinic) params.clinic = clinic;
        if (searchTerm) params.searchTerm = searchTerm;
        if (studyStatus) params.studyStatus = studyStatus;
        if (studyType) params.studyType = studyType;
        if (studyLanguage) params.studyLanguage = studyLanguage;
        if (analysisPlatform) params.analysisPlatform = analysisPlatform;
        if (severity !== undefined) params.severity = severity.toString();
    
        // if (dateStartFrom) params.dateStartFrom = dateStartFrom.toISOString();
        // if (dateStartTo) params.dateStartTo = dateStartTo.toISOString();
        // if (dateFinishFrom) params.dateFinishFrom = dateFinishFrom.toISOString();
        // if (dateFinishTo) params.dateFinishTo = dateFinishTo.toISOString();
    
        setSearchParams(params);
    }, [
        region, cluster, clinic, searchTerm, studyStatus, studyType, studyLanguage,
        analysisPlatform, severity, dateStartFrom, dateStartTo, dateFinishFrom, dateFinishTo
    ]);

    

    const fetchRegionsList = useCallback(async () => {
        if (!hasPermission!(PermissionsE["units.r"])) return;
        const regionsList = await getRegionsList();
        regionsList.sort((a: any, b: any) => b.departments_number - a.departments_number);

        let newList = regionsList.map((item: { name: any; id: any; }) => {
            return {
                label: item.name,
                value: item.id
            };
        });
        setRegions(newList);
    }, [])

    const fetchCompanyList = async (regionId: string | null) => {
        if (!hasPermission!(PermissionsE["units.r"])) return;
        const companyList = await getClustersList(regionId);
        companyList.sort((a: any, b: any) => b.departments_number - a.departments_number);

        let newList = companyList.map((item: { name: any; id: any; }) => {
            return {
                label: item.name,
                value: item.id
            };
        });
        setClusters(newList);
    }

    let fetchDepartmentsList = async (companyId: string | null) => {
        if (!hasPermission!(PermissionsE["units.r"])) return;
        let data = await getClinicsList(companyId);
        let newDepartmentsList = data;
        let newList = newDepartmentsList.map((item: { name: any; id: any; }) => {
            return {
                label: item.name,
                value: item.id
            };
        });
        setClinics(newList);
        // @ts-ignore
        setClinic(undefined);

    }

    const setDefaultClusters = async() => {
        const { connected_units } = await getValueFromLocalStorage('staff_profile');
        const clusters = connected_units.filter((cu: Unit) => isCluster(cu.region || '', cu.cluster || ''));
        let newList = clusters.map((item: { name: any; id: any; }) => {
            return {
                label: item.name,
                value: item.id
            };
        });
        setClusters(newList);

    }

    const setDefaultClinics = async() => {
        const { connected_units } = await getValueFromLocalStorage('staff_profile');
        const clinics = connected_units.filter((cu: Unit) => isClinic(cu.region || '', cu.cluster || ''));

        let newList = clinics.map((item: { name: any; id: any; }) => {
            return {
                label: item.name,
                value: item.id
            };
        });
        setClinics(newList);

    }


    useEffect(() => {
        fetchRegionsList()
    }, [fetchRegionsList]);


    useEffect(() => {
        if(!region) {
            setDefaultClusters();
        }
    }, [region]);
    //
    useEffect(() => {
        if(!cluster){
            setDefaultClinics();
        }
    }, [cluster]);


    const fetchMeasurements = async () => {
        let filter_values = {
            'search_term': debouncedSearchTerm && debouncedSearchTerm.length > 2 ? debouncedSearchTerm : '',
            'id_region': region,
            'id_company': cluster,
            'id_department': clinic,
            // 'date_start__from': formatDateAPI(dateStartFrom),
            // 'date_start__to': formatDateAPI(dateStartTo),
            // 'date_finished__from': formatDateAPI(dateFinishFrom),
            // 'date_finished__to': formatDateAPI(dateFinishTo),
            // 'study_language': studyLanguage,
            'study_type': studyType,
            'status': studyStatus,
            'analysis_platform' : analysisPlatform,
            'severity' : severity,

            // Pre-set to outfilter relevant data 
            'is_technician_inbox' : true
        }
        setIsMeasurementAvailableMore(false);
        const measurements = await listStudiesAdmin(filter_values)
        setMeasurements(measurements.data)
    }

    return (
        <>
            <PageHeader title="Technician Inbox" actions={[]} />
            <Paper sx={{ p: 2, mb: 2 }}>
                <Box display="flex" alignItems="center" sx={{ px: 2 }}>
                    <Box display="flex" sx={{ '& > div:not(:last-child)': { mr: 1 } }}>
                        {debouncedSearchTerm ? (
                            <Chip
                                label={debouncedSearchTerm}
                                onDelete={() => setSearhTerm('')}
                            />
                        ) : <></>}
                        {region && regions.find((r: any) => r.value === region) ? (
                            <Chip
                                label={regions.find((r: any) => r.value === region)?.label}
                                sx={{
                                    backgroundColor: alpha('#1D80E8', 0.45),
                                    "&:hover": {
                                        backgroundColor: alpha('#1D80E8', 0.45)
                                    }
                                }}
                                onDelete={() =>{
                                    setRegion(undefined);
                                    setCluster(undefined);
                                    setClinic(undefined);
                                }}
                            />
                        ) : <></>}
                        {cluster && clusters.find((r: any) => r.value === cluster) ? (
                            <Chip
                                label={clusters.find((r: any) => r.value === cluster)?.label}
                                sx={{
                                    backgroundColor: alpha('#1D80E8', 0.30),
                                    "&:hover": {
                                        backgroundColor: alpha('#1D80E8', 0.30)
                                    }
                                }}
                                onDelete={() => {
                                    setCluster(undefined);
                                    setClinic(undefined);
                                }}
                            />
                        ) : <></>}
                        {clinic && clinics.find((r: any) => r.value === clinic) ? (
                            <Chip
                                label={clinics.find((r: any) => r.value === clinic)?.label}
                                sx={{
                                    backgroundColor: alpha('#1D80E8', 0.15),
                                    "&:hover": {
                                        backgroundColor: alpha('#1D80E8', 0.15)
                                    }
                                }}
                                onDelete={() => setClinic(undefined)}
                            />
                        ) : <></>}
                        {studyStatus ? (
                            <Chip
                                label={studyStatusesInbox.find(ss => ss.value === studyStatus)?.label}
                                onDelete={() => setStudyStatus('')}
                            />
                        ) : <></>}
                        {studyType ? (
                            <Chip
                                label={studiesList.find(st => st.value === studyType)?.label}
                                onDelete={() => setStudyType('')}
                            />
                        ) : <></>}
                        {studyLanguage ? (
                            <Chip
                                label={languages.find(l => l.value === studyLanguage)?.label}
                                onDelete={() => setStudyLanguage('')}
                            />
                        ) : <></>}
                        {analysisPlatform ? (
                            <Chip
                                label={analysisPlatforms.find(l => l.value === analysisPlatform)?.label}
                                onDelete={() => setAnalysisPlatform(undefined)}
                            />
                        ) : <></>}
                        {severity ? (
                            <Chip
                                label={"Severity " + studySeverities.find(l => l.value === severity.toString())?.label}
                                onDelete={() => setSeverity(undefined)}
                            />
                        ) : <></>}
                        {dateStartFrom ? (
                            <Box>
                                <Chip
                                    label={formatDateToShow(dateStartFrom)}
                                    onDelete={() => setDateStartFrom(null)}
                                />
                            </Box>
                        ) : <></>}
                        {dateStartTo ? (
                            <Box>
                                <Chip
                                    label={formatDateToShow(dateStartTo)}
                                    onDelete={() => setDateStartTo(null)}
                                />
                            </Box>
                        ) : <></>}
                        {dateFinishFrom ? (
                            <Box>
                                <Chip
                                    label={formatDateToShow(dateFinishFrom)}
                                    onDelete={() => setDateFinishFrom(null)}
                                />
                            </Box>
                        ) : <></>}
                        {dateFinishTo ? (
                            <Box>
                                <Chip
                                    label={formatDateToShow(dateFinishTo)}
                                    onDelete={() => setDateFinishTo(null)}
                                />
                            </Box>
                        ) : <></>}
                    </Box>
                </Box>
                <AccordionDetails>
                    <Box mb={2}>
                        <MTextField
                            label="Search term"
                            value={searchTerm}
                            onChange={(e) => {
                                setSearhTerm(e.target.value);
                            }}
                            helperText="Device SN / ID Patient / ID Study"
                        />
                    </Box>
                    <Grid container sx={{mb: 2}} spacing={2}>
                                {regions && regions.length > 0 && (
                                    <>
                                        <Grid item xs={4}>
                                            <MSelect
                                                label="Region"
                                                value={region}
                                                options={regions}
                                                onChange={(e) => {
                                                    setRegion(e.target.value);
                                                    fetchCompanyList(e.target.value);
                                                }}
                                            />
                                        </Grid>
                                    </>
                                )}
                                {clusters && clusters.length > 0 && (
                                    <>
                                        <Grid item xs={4}>
                                            <MSelect
                                                label="Cluster"
                                                value={cluster}
                                                options={clusters}
                                                onChange={(e) => {
                                                    setCluster(e.target.value);
                                                    fetchDepartmentsList(e.target.value);
                                                }}
                                            />
                                        </Grid>
                                    </>
                                )}
                                {clinics && clinics.length > 0 && (
                                    <>
                                        <Grid item xs={4}>
                                            <MSelect
                                                label="Clinic"
                                                value={clinic}
                                                options={clinics}
                                                onChange={(e) => {
                                                    setClinic(e.target.value);
                                                }}
                                            />
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <MSelect
                                        label="Study status"
                                        value={studyStatus}
                                        options={studyStatusesInbox}
                                        onChange={(e) => setStudyStatus(e.target.value)}
                                    />
                                </Grid>
                                {hasPermission!(PermissionsE["study.r_tech"])  && (
                                    <>
                                        <Grid item xs={4}>
                                            <MSelect
                                                label="Analysis platform"
                                                value={analysisPlatform}
                                                options={analysisPlatforms}
                                                onChange={(e) => setAnalysisPlatform(e.target.value)}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <MSelect
                                                label="Study severity"
                                                value={severity?.toString()}
                                                options={studySeverities}
                                                onChange={(e) => setSeverity(Number(e.target.value))}
                                            />
                                        </Grid>
                                    </>
                            )
                            }
                            </Grid>
                </AccordionDetails>
            </Paper>
            <Paper sx={{ minHeight: '70vh', px: 4, py: 2 }}>
                {hasPermission!(PermissionsE["study.r"]) ? (
                    <Box my={2}>
                        <Box mb={2}>
                            <Typography variant="h6">Studies list</Typography>
                        </Box>
                        <InboxGrid
                            data={measurements}
                        />
                    </Box>
                ) : <></>}
            </Paper >
        </>
    );
};

export default TechnicianInbox;