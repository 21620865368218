import React, { useState } from "react";
import { alpha, Box, Chip, Typography } from "@mui/material";
import { formatHoursToReadableFormat, getDatesBetween, getHoursDifference, getIsWorkingDay, getMaxSLADate } from "../utils/utils";

const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
const MIN_HEIGHT = 25;
const MAX_HEIGHT = 75;

const getHeightPoints = (duration_hours : number) => {
  let heightCoefficient = duration_hours / 48;

  if (heightCoefficient < 0.2) {
    return MIN_HEIGHT;
  }

  if (heightCoefficient > 1) {
    return MAX_HEIGHT;
  }

  return MIN_HEIGHT + (MAX_HEIGHT - MIN_HEIGHT) * heightCoefficient;
}

const formatDateTime = (eventDate: Date): string => {
  return `${eventDate.toLocaleDateString("en-GB", { weekday: "long" })}, ${eventDate.toLocaleDateString("en-GB")} ${eventDate.toLocaleTimeString("en-GB", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  })}`;
};

const renderCircleWithText = (dateText: string, eventType: string, color: string, isEventChip: boolean = false) => {
  let message = eventType.length > 1 && !isEventChip ? `${dateText}(${eventType})` : `${dateText}`;
  
  return (
    <Box display="flex" sx={{ mt: VERTICAL_MARGINE }} alignItems="center" gap={4}>
      {/* Circle */}
      <div
        style={{
          width: `${CIRCLE_SIZE}px`,
          height: `${CIRCLE_SIZE}px`,
          backgroundColor: color,
          borderRadius: "50%",
          border: "1px solid lightgrey"
        }}
      />
      
      {/* Text next to the circle */}
      {!isEventChip ? (
        <Typography variant="body2" sx={{ fontSize: "15px", fontWeight: "bold" }}>
          {message}
        </Typography>
      ) : (
        <Box display="flex" alignItems="center" gap={1}>
          <Typography variant="body2" sx={{ fontSize: "15px", fontWeight: "bold" }}>
            {dateText}
          </Typography>
          <Chip
            label={eventType}
            size="small"
            sx={{
              fontSize: "15px",
              fontWeight: 'bold',
              backgroundColor: color
            }}
          />
        </Box>
      )}
    </Box>
  );
};


const getDateEventFormat = (event: string, event_date: Date) => {
  const formattedDate = formatDateTime(event_date);
  if (event.length > 0){
    return `${formattedDate} (${event})`;
  } else {
    return `${formattedDate}`;
  }

}

const drawVerticalDivider = (
  hoursDuration: number, 
  text: string, 
  isCountHours : boolean = true,
  lineColor: string = "#d3d3d3", // Default line color is blue
  chipColor : string = "#d3d3d3"
) => {
  let height = getHeightPoints(hoursDuration);


  let textDuration = isCountHours ? hoursDuration : 0;
  let extraText = text.length > 0 ? `(${text})` : "";
  
  let finalLineColor = isCountHours ? lineColor : "lightgrey";
  let finalChipColor = isCountHours ? lineColor : "lightgrey";

  let message = "";
  if (textDuration <= 24) {
    message = `+${formatHoursToReadableFormat(textDuration)} ${extraText}`;
  } else {
    message = `+${formatHoursToReadableFormat(textDuration)}  ${extraText}`;
  }
  

  return (
    <Box display="flex" alignItems="center" sx={{mt:VERTICAL_MARGINE}}>
      {/* Vertical Divider */}
      <Box
        sx={{
          height: `${height}px`,           // Height of the divider
          width: `${LINE_WIDTH}px`,        // Width of the divider
          backgroundColor: finalLineColor,      // Color of the divider
          marginLeft: `${(CIRCLE_SIZE - LINE_WIDTH) / 2}px`, // Margin for alignment
        }}
      />
      
      <Chip
          label={message}
          size="small" 
          sx={{
             fontSize:"13px",
             fontWeight: 'bold',
              marginLeft: "15px", 
              backgroundColor: finalChipColor
          }}
      />
    </Box>
  );
};



const CIRCLE_SIZE = 13;
const LINE_WIDTH = 3;
const VERTICAL_MARGINE = "3px";

const RED_COLOR = "#FF7276";
const DEFAULT_GREY = "grey";


interface MSLATimelineProps {
  date_upload: Date;
  date_report_received?: Date;
  date_finished?: Date;
  sla_hours: number;
  sla_working_days: number;
}

export const SLATimeline = (props: MSLATimelineProps) => {
  const { date_upload, date_report_received, date_finished, sla_hours, sla_working_days } = props;

  if (!date_upload) {
    return <Typography variant="body2" color="error">Study wasn't uploaded yet</Typography>;
  }


  const drawTimelineSectionLines = (
    dateFrom : Date,
    dateTo : Date,
    bitmask: number,
    isDrawIntermidiateDays: boolean,
    linesColorDefault : string,
    isCountHours : boolean = true
  ) => {
    let components = [];
    let duration = getHoursDifference(dateFrom, dateTo);
    let isWorkingDay = getIsWorkingDay(dateFrom,bitmask);

    if (duration < 24 || duration > 24 * 5 || !isDrawIntermidiateDays) {
      components.push(drawVerticalDivider(duration, "", isWorkingDay && isCountHours,linesColorDefault));
    }
    else {
      let datesBetweenArray = getDatesBetween(dateFrom, dateTo);
      let firstDuration = getHoursDifference(dateFrom, datesBetweenArray[0]);
      
      isWorkingDay = getIsWorkingDay(dateFrom,bitmask);
      components.push(drawVerticalDivider(firstDuration, '',isWorkingDay && isCountHours, linesColorDefault));
      components.push(renderCircleWithText(getDateEventFormat("", datesBetweenArray[0]),"",linesColorDefault,false));

      for (let i = 1; i < datesBetweenArray.length; i++) {
        let intermidiateDuration = getHoursDifference(datesBetweenArray[i], datesBetweenArray[i-1]);
        isWorkingDay = getIsWorkingDay(datesBetweenArray[i-1],bitmask);  
        components.push(drawVerticalDivider(intermidiateDuration, '', isWorkingDay && isCountHours, linesColorDefault));
        components.push(renderCircleWithText(getDateEventFormat("", datesBetweenArray[i]),"",linesColorDefault,false));
      }

      let finalDuration = getHoursDifference(datesBetweenArray[datesBetweenArray.length-1], dateTo);
      isWorkingDay = getIsWorkingDay(datesBetweenArray[datesBetweenArray.length-1],bitmask);
      components.push(drawVerticalDivider(finalDuration, '', isWorkingDay && isCountHours, linesColorDefault));

    }

    return components;

  }

  const generateTimeline = () => {
    const components = [];
    const newDateUpload = new Date(date_upload);
    const newDateReportReceived = date_report_received ? new Date(date_report_received) : null;
    const newDateFinished = date_finished ? new Date(date_finished) : null;

    let SLAMaxDate = null;

    if (sla_working_days > 0){
      SLAMaxDate = getMaxSLADate(newDateUpload, sla_hours, sla_working_days);
    }


    let datesList = [{
      type: "Interpreting",
        value: newDateUpload,
        pointColor : "#F3F5E8",
        lineColor : "#F3F5E8"
    }];

    if (newDateReportReceived !== null) {
      datesList.push({
        type: "In review",
        value: newDateReportReceived,
        pointColor : "#A3D5FF",
        lineColor : "#A3D5FF"
      })
    }

    if (newDateFinished !== null) {
      datesList.push({
        type: "Completed",
        value: newDateFinished,
        pointColor : "#CEE0C7",
        lineColor : "#CEE0C7"
      })
    }

    if (SLAMaxDate !== null) {
      datesList.push({
        type: "SLA Deadline",
        value: SLAMaxDate,
        pointColor : RED_COLOR,
        lineColor : RED_COLOR
      })
    }

    if ((newDateFinished === null || newDateFinished > new Date())) {
      datesList.push({
        type: "<<<Now>>>",
        value: new Date(),
        pointColor : "#FFB347",
        lineColor : "#FFE4B5"
      })  
    }


    
    datesList.sort((a, b) => a.value.getTime() - b.value.getTime());
    let isSLAViolated = false;
    let isCountHours = true;

    for (let i = 0; i < datesList.length; i++){
      let current_item = datesList[i];
      components.push(renderCircleWithText(getDateEventFormat("", current_item.value),
                                           current_item.type,
                                           current_item.pointColor,
                                           true
                                          ));


      if(!isSLAViolated && current_item.type == "SLA Deadline") {
        isSLAViolated = true;
      }

      if(current_item.type == "Completed") {
        isCountHours = false;
      }


      if(i+1 < datesList.length){
        let next_item = datesList[i+1];
        let processingComponents = drawTimelineSectionLines(current_item.value, 
          next_item.value, 
          sla_working_days, 
          true,
          isSLAViolated ? RED_COLOR : current_item.lineColor,
          isCountHours 
        );
        components.push(...processingComponents);
      }
    }

    return components;
  };

  return (
    <>
    <Box>
      {generateTimeline()}
      
    </Box>

    </>
  )
}


interface MSLATimelineScheduleProps {
  sla_working_days: number;
}



export const SLATimelineSchedule = (props: MSLATimelineScheduleProps) => {
  const {sla_working_days } = props;

  const generateTimeline = () => {
    const components = [];

    for (let i = 0; i < 7; i++) {
      let isWorkingDay = (sla_working_days & (1 << i)) !== 0;
      components.push(renderCircleWithText(
        `${days[i]}`,
        "",
        isWorkingDay? "lightgreen" : "lightgrey"));
      components.push(drawVerticalDivider(
          24, 
          isWorkingDay ? "Count in total SLA" : 'Do not count in total SLA',
          isWorkingDay,
          isWorkingDay ? "lightblue" : "lightgrey",
          isWorkingDay ? "lightblue" : "lightgrey"
        )
      );
    }

    return components;
  } 


  return (
    <>
    <Box>
      {generateTimeline()}
    </Box>

    </>
  )
}
