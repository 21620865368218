import Box from '@mui/material/Box';
import MModal from "../MModal";
import { useState } from "react";
import { Typography } from "@mui/material";
import MTextField from "../MTextField";
import { MButton } from "../MButton";
import { addInfrastructure, deleteInfrastructure, updateInfrastructure } from "../../api/infrastructures";
import MTable, { MTableActionsButtons, MTableProps, TableHeading } from './MTable';
import { PermissionsE, usePermissions } from '../../context/usePermissions';

export type FieldType = 'id' | 'url' | 'report' | 'date_start';

interface MInfrastructuresTableProps extends MTableProps {
    onRowClick?: (id: string) => void;
}

export const MInfrastructuresTable = (props: MInfrastructuresTableProps) => {
    const { hasPermission } = usePermissions();
    const canWrite = hasPermission!(PermissionsE["infrastructures.w"]);

    const [busy, setBusy] = useState(false);
    const {headings, rows, sx, onRowClick} = props;
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState<any>(null);
    const [isEditModal, setIsEditModal] = useState<boolean>(false);


    const sendDeleteInfrastructure = async () => {
        setBusy(true);
        await deleteInfrastructure(selectedRow?.id);
        window.location.reload();
    }

    const renderCell = (heading: TableHeading, row: any) => {
        switch (heading.field) {
            case "actions":
                return canWrite ? (
                    <MTableActionsButtons 
                        rowData={row}
                        onEdit={() => {
                            setSelectedRow(row);
                            setIsEditModalOpen(true);
                            setIsEditModalOpen(true)
                        }}
                        onDelete={()=>{
                            setSelectedRow(row);
                            setIsDeleteModalOpen(true);
                        }}
                    />
                ) : <></>;
            default:
                return row[heading.field];
        }
    };

    const handleInfrastructureChange = (keyToUpdate: string, newValue: string | number) => {
        let newDict = {...selectedRow};
        newDict[keyToUpdate] = newValue;
        setSelectedRow(newDict);
    };


    const submitUpdatePackage = async () => {
        if (!canWrite) return;
        let data = {
            data: selectedRow.data,
            dashboard: selectedRow.dashboard,
            device: selectedRow.device,
            users: selectedRow.users,
            name: selectedRow.name
        }
        await updateInfrastructure(selectedRow.id, data);
        window.location.reload();
    }

    const submitPackage = async () => {
        if (!canWrite) return;
        let data = {
            data: selectedRow.data,
            dashboard: selectedRow.dashboard,
            device: selectedRow.device,
            users: selectedRow.users,
            name: selectedRow.name
        }
        await addInfrastructure(data);
        window.location.reload();
    }

    const saveHandler = async () => {
        if (!canWrite) return;
        setBusy(true);
        if (isEditModal) {
            await submitUpdatePackage().finally(() => setTimeout(() => setBusy(false), 500));
        } else {
            await submitPackage().finally(() => setTimeout(() => setBusy(false), 500));
        }
    }

    return (
        <div>
            <MModal
                open={isDeleteModalOpen}
                handleClose={() => {
                    setIsDeleteModalOpen(false)
                }}
                width={480}
                height="auto"
            >
                <Box p={3}>
                    <Typography variant="h4" sx={{textAlign: "center", mb: 4}} paragraph={true}>
                        Infrastructure deletion
                    </Typography>

                    <Typography variant="h6" sx={{textAlign: "center", mt: 4}}>
                        Confirm deletion of "{selectedRow?.name}"
                    </Typography>


                    <Box sx={{textAlign: "center", mt: 4}}>
                        <MButton disabled={busy} color="error" onClick={() => setIsDeleteModalOpen(false)}>Cancel</MButton>
                        <MButton busy={busy} sx={{ml: 2}} onClick={sendDeleteInfrastructure}>Save</MButton>
                    </Box>
                </Box>
            </MModal>
            <MModal
                open={isEditModalOpen}
                handleClose={() => {
                    setIsEditModalOpen(false)
                }}
                width={480}
                height="auto"
            >
                <Box p={3}>
                    <Typography variant="h4" sx={{textAlign: "center", mb: 4}} paragraph={true}>
                        Infrastructure
                    </Typography>

                    <MTextField
                        type="text"
                        sx={{textAlign: "center", mb: 2}}
                        label="Name"
                        value={selectedRow?.name}
                        onChange={(e) => {handleInfrastructureChange("name",
                            e.target.value)}}
                    />
                    <MTextField
                        type="text"
                        sx={{textAlign: "center", mb: 2}}
                        label="Data"
                        value={selectedRow?.data}
                        onChange={(e) => {handleInfrastructureChange("data",
                            e.target.value)}}
                    />
                    <MTextField
                        type="text"
                        sx={{textAlign: "center", mb: 2}}
                        label="Device"
                        value={selectedRow?.device}
                        onChange={(e) => {handleInfrastructureChange("device",
                            e.target.value)}}
                    />
                    <MTextField
                        type="text"
                        sx={{textAlign: "center", mb: 2}}
                        label="Users"
                        value={selectedRow?.users}
                        onChange={(e) => {handleInfrastructureChange("users",
                            e.target.value)}}
                    />
                    <MTextField
                        type="text"
                        sx={{textAlign: "center", mb: 2}}
                        label="Dashboard"
                        value={selectedRow?.dashboard}
                        onChange={(e) => {handleInfrastructureChange("dashboard",
                            e.target.value)}}
                    />
                    <Box sx={{textAlign: "center", mt: 4}}>
                        <MButton disabled={busy} color="error" onClick={() => setIsEditModalOpen(false)}>Cancel</MButton>
                        <MButton busy={busy} sx={{ml: 2}} onClick={saveHandler}>Save</MButton>
                    </Box>
                </Box>
            </MModal>

            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width='100%'
                sx={{mb: 2}}
            >

                <Typography variant="h4">
                    Infrastructures
                </Typography>

                {canWrite ? (
                    <MButton variant="contained" color="primary" onClick={() => {
                        setIsEditModal(false);
                        setIsEditModalOpen(true);
                        setSelectedRow(null)
                    }}>
                        Add infrastructure
                    </MButton>
                ) : <></>}
            </Box>
            <MTable 
                headings={headings}
                rows={rows}
                sx={sx}
                renderCell={renderCell}
            />  
        </div>
    );
}

export default MInfrastructuresTable;
