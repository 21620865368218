import { ReactElement } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  pt: 1,
  px: 4,
  pb: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: 2.5
};

interface MModalProps {
  children: ReactElement;
  open: boolean;
  handleClose?: () => void;
  width?: number | string;
  height?: number | string;
}

export const MModal = (props: MModalProps) => {
  const { children, open, handleClose = () => {return false;}, width = 480, height = 255 } = props;

  const handleBackdropClick = () => {
    //these fail to keep the modal open
    //event.stopPropagation();
    return false;
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{overflow:"scroll"}}
    >
      <Box sx={{ ...style, width, height}}>{children}</Box>
    </Modal>
  );
}

export default MModal;
