import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/system';
import { datesDifference, isClinic, isCluster, isRegion } from "../../utils/utils";
import { alpha, Chip, Switch } from "@mui/material";
import { useState } from "react";
import Typography from "@mui/material/Typography";
import { MButton } from "../MButton";
import MModal from '../MModal';
import { baseUrls } from "../../routes";
import MTable, { MTableActionsButtons } from './MTable';
import { useNavigate } from 'react-router-dom';
import { isAccountAdminByRole, isAdmin, isSuperAdmin, useAuth } from '../../context/useAuth';
import { AccountRolesEnum } from '../../containers/AddEditAccountForm/formUtils';

export type FieldType = 'name' | 'status' | 'report';

let popupsData = Object({
    "activate": {
        "header": "Account activation",
        "body": "Confirm account activation"
    },
    "de-activate": {
        "header": "Account de-activation",
        "body": "Confirm account de-activation"
    },
    "delete": {
        "header": "Account deletion",
        "body": "Confirm account deletion"
    }
})
export interface TableHeading {
    fieldName: string,
    field: string
    type?: FieldType;
}

interface MClientAccountsTableProps {
    headings: TableHeading[];
    rows: any[];
    sx?: SxProps<Theme>;
    entity?: 'clinic' | 'cluster' | 'region';
    onUpdate?: (id: string, data: any) => Promise<any>;
    onDelete?: (id: string) => Promise<any>;
    updateLink?: string;
}

export const MClientAccountsTable = (props: MClientAccountsTableProps) => {
    const { user } = useAuth();

    const navigate = useNavigate();
    const [busy, setBusy] = useState(false);
    const { headings, rows, onUpdate, onDelete, updateLink, entity } = props;

    const [popupType, setPopupType] = useState("delete");
    const [isPopUpOpen, setIsPopUpOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

    const prepareDataForActivationStatusChange = (row: any) => {
        return {
            "new_is_active": !row.is_active,
            "action": "change_is_active"
        }
    }

    const handleCellAction = async () => {
        let data = null;
        let id = (selectedRow) ? selectedRow['id'] : "";
        setBusy(true);

        // modify update
        switch (popupType) {
            case "delete":
                if (selectedRow && onDelete) {
                    await onDelete(id).finally(() => setTimeout(() => setBusy(false), 500));
                }
                break
            case "activate":
                data = prepareDataForActivationStatusChange(selectedRow);
                if (data && onUpdate) {
                    await onUpdate(id, data).finally(() => setTimeout(() => setBusy(false), 500));
                }

                break
            case "de-activate":
                data = prepareDataForActivationStatusChange(selectedRow);
                if (data && onUpdate) {
                    await onUpdate(id, data).finally(() => setTimeout(() => setBusy(false), 500));
                }

                break
        }
        setIsPopUpOpen(false);
        window.location.reload();
    }

    const renderUnits = (units: any[], isAccountAdmin : boolean) => {
        if (!units.length) return isAccountAdmin ? 'Everything' : "Nothing";

        return units.map((unit, key) => (
            <Chip
                label={unit.name}
                sx={{
                    m: '2px',
                    backgroundColor: isRegion(unit.region, unit.cluster) ? alpha('#1D80E8', 0.45) :
                        isCluster(unit.region, unit.cluster) ? alpha('#1D80E8', 0.30) :
                            isClinic(unit.region, unit.cluster) ? alpha('#1D80E8', 0.15) : 'inherit',
                    "&:hover": {
                        backgroundColor: isRegion(unit.region, unit.cluster) ? alpha('#1D80E8', 0.45) :
                            isCluster(unit.region, unit.cluster) ? alpha('#1D80E8', 0.30) :
                                isClinic(unit.region, unit.cluster) ? alpha('#1D80E8', 0.15) : 'inherit',
                    }
                }}
                key={key}
                onClick={() => {
                    let currentLink: string = ''
                    if (isRegion(unit.region, unit.cluster)) {
                        currentLink = `${baseUrls.regions}/${unit.id}`;
                    }

                    if (isCluster(unit.region, unit.cluster)) {
                        currentLink = `${baseUrls.clusters}/${unit.id}`;
                    }

                    if (isClinic(unit.region, unit.cluster)) {
                        currentLink = `${baseUrls.clinics}/${unit.id}`;
                    }

                    if (currentLink) navigate(currentLink)
                }}
            />
        ));
    }

    const renderCell = (heading: TableHeading, row: any) => {
        let value = row[heading.field]

        const isAccountAdmin = isAccountAdminByRole(row.role);

        switch (heading.field) {
            case 'name':
                return value;
            case 'units':
                return renderUnits(value, isAccountAdmin);
            case 'last_token_obtain':
                if (value === null || value === undefined) {
                    return "Never"
                }
                return datesDifference(new Date(value * 1000))
            case 'is_active':
                if (isAccountAdmin && !isSuperAdmin(user)) return;
                return <Switch
                    checked={row.is_active}
                    onChange={(e) => {
                        setSelectedRow(row);
                        setIsPopUpOpen(true);
                        setPopupType((e.target.checked) ? "activate" : "de-activate");
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                />
            case 'actions':
                if (isAccountAdmin && !isSuperAdmin(user)) return;
                return (
                    <MTableActionsButtons
                        rowData={row}
                        onEdit={onUpdate ? () => {
                            if (updateLink) {
                                if (entity) {
                                    navigate(`${updateLink}&accountId=${row.id}`)
                                } else {
                                    navigate(`${updateLink}?accountId=${row.id}`)
                                }
                            }
                        } : undefined}
                        onDelete={onDelete && +user?.staff_profile.id !== row.id ? () => {
                            setSelectedRow(row);
                            setIsPopUpOpen(true);
                            setPopupType("delete");
                        } : undefined}
                    />
                );
            default:
                return value;
        }
    };

    const actionModal = (
        <MModal
            open={isPopUpOpen}
            handleClose={() => {
                setIsPopUpOpen(false)
            }}
            width={480}
            height="auto"
        >
            <Box p={3}>
                <Typography variant="h4" sx={{ textAlign: "center", mb: 10 }} paragraph={true}>
                    {popupsData[popupType]['header']}
                </Typography>
                <Typography variant="body1" sx={{ mb: 15 }}>
                    {popupsData[popupType]['body']}
                </Typography>
                <Box sx={{ textAlign: "center" }}>
                    <MButton disabled={busy} sx={{ mr: 2 }} color="error" onClick={() => setIsPopUpOpen(false)}>Cancel</MButton>
                    <MButton busy={busy} onClick={() => handleCellAction()}>Confirm</MButton>
                </Box>
            </Box>
        </MModal>
    );

    return (
        <>
            {actionModal}
            <MTable
                headings={headings}
                rows={rows}
                renderCell={renderCell}
            />
        </>
    );
}

export default MClientAccountsTable;
