import { Box, Grid, Paper, Typography } from "@mui/material";
import PageHeader from "../containers/PageHeader";
import { getValueFromLocalStorage } from "../api/auth";
import { useCallback, useEffect, useState } from "react";
import UnitsListGrid from "../containers/Grids/UnitsListGrid";
import { isClinic, isCluster, isRegion } from "../utils/utils";
import { Unit } from "../api/accounts";
import { PermissionsE, usePermissions } from "../context/usePermissions";
import { NO_PERMISSIONS_MESSAGE } from "../utils/constants";

export const MCUHome = () => {
  const { hasPermission } = usePermissions();

  const [regions, setRegions] = useState([]);
  const [clusters, setClusters] = useState([]);
  const [clinics, setClinics] = useState([]);

  const connectedUnits = useCallback(async () => {
    const { connected_units } = await getValueFromLocalStorage('staff_profile');
    const regions = connected_units.filter((cu: Unit) => isRegion(cu.region || '', cu.cluster || ''));
    const clusters = connected_units.filter((cu: Unit) => isCluster(cu.region || '', cu.cluster || ''));
    const clinics = connected_units.filter((cu: Unit) => isClinic(cu.region || '', cu.cluster || ''));
    setRegions(regions);
    setClusters(clusters);
    setClinics(clinics);
  }, [])

  useEffect(() => {
    connectedUnits()
  }, [connectedUnits])

  if (!hasPermission!(PermissionsE["units.r"])) return (
    <Paper sx={{ padding: 3 }}>
      <Typography>{NO_PERMISSIONS_MESSAGE}</Typography>
    </Paper>
  )

  return (
    <>
      <PageHeader
        title="Connected units"
      />
      <Paper sx={{ minHeight: '70vh', px: 4, py: 5.5 }}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Box mb={2}>
              <Typography variant="h4" paragraph={true}>Regions</Typography>
            </Box>
            <UnitsListGrid type="region" data={regions} />
          </Grid>
          <Grid item xs={12}>
            <Box mb={2}>
              <Typography variant="h4" paragraph={true}>Clusters</Typography>
            </Box>
            <UnitsListGrid type="cluster" data={clusters} />
          </Grid>
          <Grid item xs={12}>
            <Box mb={2}>
              <Typography variant="h4" paragraph={true}>Clinics</Typography>
            </Box>
            <UnitsListGrid type="clinic" data={clinics} />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default MCUHome;