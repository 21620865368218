import { useNavigate } from "react-router-dom";
import { baseUrls } from "../../routes";
import AdminStudiesTable from "../../components/MTables/AdminStudiesTable";

const tableHeadings = [
    {
        fieldName: 'ID',
        field: 'id'
    },
    {
        fieldName: 'Devices S\N',
        field: 'device_serial_number'
    },
    {
        fieldName: 'Start date',
        field: 'date_start'
    },
    {
        fieldName: 'Upload date',
        field: 'date_upload'
    },
    {
        fieldName: 'Signing date',
        field: 'date_finished'
    },
    {
        fieldName: 'Status',
        field: 'status'
    },
    {
        fieldName: 'Report',
        field: 'report'
    },
];

interface AdminMeasurementsGridProps {
    data: any[];
    onReportCellClick?: (url: string) => void;
}

export const AdminStudiesGrid = (props: AdminMeasurementsGridProps) => {
    const navigate = useNavigate();

    const { data } = props;

    const rows = data.map((row) => ({
        ...row
    }));

    return (
        <AdminStudiesTable
            headings={tableHeadings}
            rows={rows}
            sx={{ paddingX: 3.5, paddingY: 2.5 }}
            onRowClick={(id) => navigate(`${baseUrls.study}/${id}`)}
        />
    );
};

export default AdminStudiesGrid;