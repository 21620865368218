import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MStatusPlates, { MSeverityPlates } from "../components/MStatusPlates";
import { formatDateTimeToShow, formatHoursToReadableFormat, formatStudyType } from "../utils/utils";
import { MButton } from "../components/MButton";
import { baseUrls } from "../routes";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import MTextField from "../components/MTextField";
import { StudyStatusesEnum, updateStudy } from "../api/study";
import { analysisPlatforms, languages } from "../utils/constants";
import MSelect from "../components/MSelect";
import { PermissionsE, usePermissions } from "../context/usePermissions";
import { Grid } from "@mui/material";
import { SLATimeline, SLATimelineSchedule } from "../components/SLATImeline";

import MModal from "../components/MModal";
// import { SLATimeline } from "../components/SLATImeline";
const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

interface ReportItem {
    title: string;
    button?: JSX.Element;
    dateTitle: string;
    date?: Date | string;
    report?: string;
}

export const StudyInfoAdmin = (study: any) => {
    const navigate = useNavigate();
    const { hasPermission } = usePermissions();
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [idTrack, setIdTrack] = useState<string>(study?.id_track || '');
    const [studyLanguage, setStudyLanguage] = useState<string>(study?.study_language || '');
    const [jiraKey, setJiraKey] = useState<string>(study?.jira_key || '');
    const [idOrgExternal, setIdOrgExternal] = useState<string>(study?.id_organization_external || '');
    const [analysisPlatform, setAnalysisPlatform] = useState<string>(study?.analysis_platform || '');
    const [isRawDataEnabled, setIsRawDataEnabled] = useState<boolean>(study?.is_raw_data_accessible || false);
    const [source, SetSource] = useState<string>(study?.source || "unknown");

    const [slaCurrentHours, setSLACurrentHours] = useState<number>(study?.current_sla_hours || 0);
    const [slaMaxHours, setSLAMaxHours] = useState<number>(study?.sla_hours || 0);
    const [slaWorkingDays, setSLAWorkingDays] = useState<number>(study?.sla_working_days || 0);

    const [isStudySLAModalOpen, setIsStudySLAModalOpen] = useState<boolean>(false);

    const [busy, setBusy] = useState<boolean>(false);
    

    if (!study) return <></>;

    const finalReport: ReportItem | null = study?.date_report_generated ? {
        title: 'Final Report',
        button: hasPermission!(PermissionsE["tab.review"]) ? (
            <MButton
                disabled={!study?.report}
                onClick={(e) => {
                    e.stopPropagation();
                    navigate(`${baseUrls.adminFinalReportReviewBase}/${study.id}`)
                }}
            >
                Review Report
            </MButton>
        ) : <></>,
        dateTitle: 'Generated on:',
        date: study?.date_report_generated,
        report: study?.report
    } : null;

    const sourceReport: ReportItem | null = study?.date_source_report_received ? {
        title: 'Source Report',
        dateTitle: 'Received on:',
        date: study?.date_source_report_received,
        report: study?.source_report
    } : null

    const reportItems = [finalReport!, sourceReport!].filter(Boolean);

    const updateStudyHandler = async () => {
        setBusy(true);
        await updateStudy({
            id_track: idTrack,
            study_language: studyLanguage,
            jira_key: jiraKey,
            id_organization_external: idOrgExternal,
            analysis_platform: analysisPlatform,
            is_raw_data_accessible: isRawDataEnabled,
            sla_hours : slaMaxHours,
            sla_working_days : slaWorkingDays
        },
            study?.id).finally(() => setTimeout(() => setBusy(false), 500));
    }

    const toggleDay = (index: number) => {
        const newBitmask = slaWorkingDays ^ (1 << index);
        setSLAWorkingDays(newBitmask);
      };

    return (
        <>
            <MModal
                open={isStudySLAModalOpen}
                handleClose={() => {
                    setIsStudySLAModalOpen(false);
                }}
                width="90%"
                height="98%"
                
            >
                <Box width="100%" 
                height="95%" 
                overflow="hidden" 
                display="flex" 
                flexDirection="column" 
                >
                    {/* Header */}
                    <Typography variant="h4" align="center">
                        SLA Details
                    </Typography>

                    {/* Two Parallel Boxes */}
                    <Box display="flex" width="100%" overflow="hidden" mt={1}>
                        
                        <Box flexDirection="column"  display="flex" width="38%" overflow="hidden" p={1}>
                            <Typography variant="h5" sx={{mb:2}}>
                                Current Study SLA
                            </Typography>
                            <SLATimeline 
                                date_report_received={study.date_report_generated} 
                                date_upload={study.date_upload}
                                date_finished={study.date_finished} 
                                sla_hours={slaMaxHours} 
                                sla_working_days={slaWorkingDays} 
                            />
                        </Box>
                        <Box width="12%" display="flex" flexDirection="column" p={1}>
                            <Typography variant="body1" sx={{mb:2}}>
                                Current SLA  
                            </Typography>
                            <Typography variant="h4" sx={{mb:2}}>
                                <b>{slaCurrentHours.toFixed(1)}h</b>  
                            </Typography>
                        </Box>
                        <Box width="38%" display="flex" flexDirection="column" p={1} >
                            <Typography variant="h5" sx={{mb:2}}>
                                Study SLA schedule  
                            </Typography>
                            <Typography variant="body2" sx={{mb:2}}>
                                This timeline shows what days count to total SLA and which are not according to Back-office schedule for current study/clinic  
                            </Typography>
                            <SLATimelineSchedule sla_working_days={slaWorkingDays}/>
                        </Box>
                        <Box width="12%" display="flex" flexDirection="column" p={1}>
                            <Typography variant="body1" sx={{mb:2}}>
                                SLA Max hours  
                            </Typography>
                            <Typography variant="h4" sx={{mb:2}}>
                                <b>{slaMaxHours.toFixed(0)}h</b>  
                            </Typography>
                        </Box>
                    </Box>
                    <Box width="100%" 
                    alignItems="center" 
                    display="flex" 
                    position="absolute" 
                    bottom={0}
                    flexDirection="column" >
                        <MButton
                            onClick={() => {setIsStudySLAModalOpen(false)}} 
                            sx={{width: "25%", mb: 2}}>Close</MButton>
                    </Box>
                </Box>
            </MModal>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container alignItems="center">
                        <Typography
                            variant="h4"
                            fontStyle={{ "color": "#4F4949", "fontWeight": "bold" }}
                        >
                            Study details {' '}
                        </Typography>
                        <Box ml={1}>
                            <MStatusPlates value={study ? study?.status : undefined} />
                        </Box>
                        {(hasPermission!(PermissionsE["study.w_tech"]) || hasPermission!(PermissionsE["study.w_meta"])) && (
                            <Box ml={1}>
                                {isEdit ?
                                    <>
                                        <>
                                            <MButton variant="text"
                                                hidden={!isEdit}
                                                color="error"
                                                onClick={() => {
                                                    setIsEdit(false);
                                                }}>
                                                Cancel
                                            </MButton>
                                            <MButton
                                                busy={busy}
                                                variant="text"
                                                hidden={!isEdit}
                                                onClick={() => {
                                                    setIsEdit(false);
                                                    updateStudyHandler();
                                                }}
                                            >
                                                Save
                                            </MButton>
                                        </>
                                    </> : (
                                        <MButton variant="text"
                                            onClick={() => {
                                                setIsEdit(true);
                                            }}
                                        >
                                            Edit
                                        </MButton>
                                    )}
                            </Box>
                        )
                        }

                    </Grid>
                </Grid>
                <Grid item xs={4}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <MTextField
                                label="Enrollment"
                                value={study?.enrollment}
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <MTextField
                                label="Study creation date"
                                value={study?.date_start ? formatDateTimeToShow(study?.date_start) : 'N/A'}
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <MTextField
                                label="Analysis submission date"
                                value={study?.date_upload_processing ? formatDateTimeToShow(study?.date_upload_processing) : 'N/A'}
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <MTextField
                                label="Signing date"
                                value={study?.date_finished ? formatDateTimeToShow(study?.date_finished) : 'N/A'}
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <MTextField
                                label="Study type"
                                value={formatStudyType(study?.study_type)}
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <MSelect
                                label="Study language"
                                disabled={!isEdit}
                                value={studyLanguage}
                                options={languages}
                                onChange={(e) => setStudyLanguage(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <MTextField
                                label="Study duration"
                                value={study?.duration_final ? study?.duration_final.toString() + " days" : "N/A"}
                                disabled={true}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6" sx={{ mb: 0.5, color: "text.primary" }}>
                                SLA Status
                                (<MButton onClick={() => {setIsStudySLAModalOpen(true)}} variant="text" size="small">
                                    View Details
                                </MButton>)
                            </Typography>
                            <Typography variant="h6" >
                                <b>{formatHoursToReadableFormat(slaCurrentHours)} / {slaMaxHours}h ({(100*slaCurrentHours / slaMaxHours).toFixed(0)}%)</b>
                            </Typography>
                            {/* <MTextField
                                label="Study duration<b>Test</b>"
                                value={study?.duration_final ? study?.duration_final.toString() + " days" : "N/A"}
                                disabled={true}
                            /> */}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={1}></Grid>
                {
                    hasPermission!(PermissionsE["study.r_tech"]) ? (
                        <Grid item xs={4}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <MTextField
                                        label="Jira Key"
                                        disabled={!isEdit || !hasPermission!(PermissionsE["study.w_tech"])}
                                        value={jiraKey}
                                        onChange={(e) => setJiraKey(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <MTextField
                                        label="ID Track"
                                        disabled={!isEdit || !hasPermission!(PermissionsE["study.w_tech"])}
                                        value={idTrack}
                                        onChange={(e) => setIdTrack(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <MSelect
                                        label="Analysis platform"
                                        value={analysisPlatform}
                                        disabled={!isEdit || !hasPermission!(PermissionsE["study.w_tech"])}
                                        options={analysisPlatforms}
                                        onChange={(e) => setAnalysisPlatform(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <MSelect
                                        label="Raw data access(Override clinic settings)"
                                        value={isRawDataEnabled ? "true" : "false"}  // Convert boolean to string
                                        disabled={!isEdit || !hasPermission!(PermissionsE["study.w_tech"])}
                                        options={[
                                            { label: "Enabled", value: "true" },
                                            { label: "Disabled", value: "false" }
                                        ]}
                                        onChange={(e) => setIsRawDataEnabled(e.target.value === "true")}  // Convert string back to boolean
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <MTextField
                                        label="ID Organization External"
                                        value={idOrgExternal}
                                        disabled={!isEdit || !hasPermission!(PermissionsE["study.w_tech"])}
                                        onChange={(e) => setIdOrgExternal(e.target.value)}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <MTextField
                                        label="Study duration(raw)"
                                        value={study?.duration_raw ? study?.duration_raw.toFixed(1) + "h" : "N/A"}
                                        disabled={true}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <MTextField
                                        label="Study duration(analyzed)"
                                        value={study?.duration_analyzed ? study?.duration_analyzed.toFixed(1) + "h" : "N/A"}
                                        disabled={true}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <MTextField
                                        label="Creation platform"
                                        value={source}
                                        disabled={true}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <MTextField
                                        label="SLA Max Hours"
                                        value={slaMaxHours}
                                        type="number"
                                        InputProps={{ min: 24, max: 100 }}
                                        onChange={(e: any) => {
                                            let numericValue = Number(e.target.value);
                                            if (!isNaN(numericValue)) {
                                                if(numericValue > 99) {
                                                    numericValue =  99;
                                                }

                                              setSLAMaxHours(numericValue);
                                            }
                                          }}  
                                        disabled={!isEdit || !hasPermission!(PermissionsE["study.w_tech"])}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                        <Typography variant="h6" paragraph={true}>Back-office schedule(working days)</Typography>
                                        
                                    {days.map((day, index) => (
                                        <>
                                            <label key={index}>
                                                <input
                                                type="checkbox"
                                                checked={(slaWorkingDays & (1 << index)) !== 0}
                                                onChange={() => toggleDay(index)}
                                                disabled={!isEdit || !hasPermission!(PermissionsE["study.w_tech"])}
                                                />
                                                {day}
                                            </label>
                                            <br/>
                                        </>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                    ) : <></>
                }
                <Grid item xs={3}>
                    {study.analysis_platform === "amps_cers" &&
                        hasPermission!(PermissionsE["study.r_tech"]) &&
                        study.status !== StudyStatusesEnum.inprogress &&
                        <>
                            <MButton sx={{ "width": "100%" }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    navigate(`${baseUrls.technicianStudyCheckpointBase}/${study.id}`)
                                }}>
                                Process raw data
                            </MButton>
                            <br />
                            <br />
                        </>
                    }

                    {reportItems.map((r, rkey) => (
                        <Box key={rkey} mb={2}>
                            <Box mb={2} display="flex" alignItems="center">
                                <Typography variant="h5">{r.title}</Typography>
                                {r.button ? <Box ml={6}>{r.button}</Box> : <></>}
                            </Box>
                            <Box display="flex" alignItems="center" sx={{ fontWeight: '400' }}>
                                <Box mr={4}>
                                    <Box>
                                        <Typography variant="body1">{r.dateTitle}</Typography>
                                    </Box>
                                    <Box>{r.date ? formatDateTimeToShow(r.date) : '—'}</Box>
                                </Box>
                                <Box>
                                    {r.report ? (
                                        <Box
                                            display="flex"
                                            sx={{ textDecoration: 'underline', fontWeight: '600' }}
                                            onClick={(e: any) => {
                                                e.stopPropagation();
                                                window.open(r.report, "_blank");
                                            }}
                                        >
                                            View Report
                                        </Box>
                                    ) : '—'}
                                </Box>
                            </Box>
                        </Box>
                    ))}
                    {study?.severity && <MSeverityPlates value={study ? study?.severity : undefined} />}
                </Grid>
            </Grid>
        </>
    )
}