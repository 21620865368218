import { networkLayer } from './networkLayer';
import { config } from "../config"
import {BaseDeviceInterface} from "./device";


export interface RegionInterface extends BaseDeviceInterface {
    name: string;
}
export const getRegionsList = async () => {
    return await networkLayer('get', `${config.baseUrl}/regions/list/`);
}

export const addRegion = async (data:RegionInterface) => {
    return await networkLayer('post', `${config.baseUrl}/regions/create/`,data);
}

export const getRegion = async (id:string) => {
    return await networkLayer('get', `${config.baseUrl}/regions/${id}/detail/`);
}

export const updateRegion = async (data:any, id:any) => {
    return await networkLayer('patch',`${config.baseUrl}/regions/${id}/update/`,data)
}