import { useNavigate } from "react-router-dom";
import { formatShortDescription } from '../../utils/utils';
import MFirmwarePackagesTable, { TableHeading } from "../../components/MTables/MFirmwarePackagesTable";
import { PermissionsE, usePermissions } from "../../context/usePermissions";

interface FirmwarePackagesGridProps {
    data: any[];
    // clientId?: string;
}

export const FirmwarePackagesGrid = (props: FirmwarePackagesGridProps) => {
    const { data } = props;
    const { hasPermission } = usePermissions();
    const canWrite = hasPermission!(PermissionsE["fw_packages.w"]);

    const tableHeadings = [
        {
            fieldName: 'Short name',
            field: 'short_name'
        },
        {
            fieldName: 'Description',
            field: 'description'
        },
        {
            fieldName: 'Link',
            field: 'link'
        },
        {
            fieldName: 'Md5 Hash',
            field: 'md5'
        },
        canWrite ? {
            fieldName: 'Actions',
            field: 'actions'
        } : null
    ].filter(Boolean) as TableHeading[];

    const rows = data.map((row) => ({
        ...row,
        short_name: formatShortDescription(row.firmware_major,
            row.firmware_minor,
            row.firmware_build,
            row.hardware_revision),
        description: row.description,
        md5: row.md5
    }));

    return (
        <MFirmwarePackagesTable
            headings={tableHeadings}
            rows={rows}
        />
    );
};

export default FirmwarePackagesGrid;