import { useNavigate } from "react-router-dom";
import { MStudyTable, FieldType } from '../../components/MTables/MStudyTable';
import { formatName, formatEnrollment, formatDateToShow, isDraft, formatStudyType } from '../../utils/utils';
import { baseUrls } from "../../routes";

const tableHeadings = [
  {
    fieldName: 'Patient',
    field: 'patient',
    type: 'name' as FieldType
  }, 
  {
    fieldName: 'Status',
    field: 'status',
    type: 'status' as FieldType
  }, 
  {
    fieldName: 'Date Created',
    field: 'date_start'
  }, 
  {
    fieldName: 'Enrollment',
    field: 'enrollment'
  }, 
  {
    fieldName: 'Study type',
    field: 'study_type'
  }, 
  {
    fieldName: 'Reports',
    field: 'report',
    type: 'report' as FieldType
  }
];

interface StudyGridProps {
  data: any[];
  onReportCellClick?: (url: string) => void;
}

export const StudyGrid = (props: StudyGridProps) => {
  const navigate = useNavigate();

  const { data } = props;

  const rows = data.map((row) => ({
    ...row,
    status: row.status,
    date_start: formatDateToShow(row.date_start),
    enrollment: formatEnrollment(row.enrollment),
    patient: isDraft(row) ? formatName(row.draft_data.patient?.first_name, row.draft_data.patient?.last_name) : formatName(row.patient?.first_name, row.patient?.last_name),
    study_type: formatStudyType(row.study_type),
    report: row.report
  }));

  return (
    <MStudyTable 
      headings={tableHeadings}
      rows={rows}
      sx={{ paddingX: 3.5, paddingY: 2.5 }}
      onRowClick={(id) => navigate(`${baseUrls.study}/${id}`)}
    />
  );
};

export default StudyGrid;