import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/system';
import { MSLAStatusPlates, MStatusPlates } from '../MStatusPlates'
import {baseUrls} from "../../routes";
import {Link} from "react-router-dom";
import MTable from './MTable';
import { formatHoursToReadableFormat } from '../../utils/utils';
import { SLAStatusesEnum } from '../../api/study';

export type FieldType = 'id' | 'url' | 'report' | 'date_start';

export interface TableHeading {
    fieldName: string,
    field: string
    type?: FieldType;
}

interface AdminMeasurementsTableProps {
    headings: TableHeading[];
    rows: any[];
    onRowClick?: (id: string) => void;
    sx?: SxProps<Theme>;
}

export const getSLAStatusPlate = (row:any) => {
    let sla_diff = row['sla_hours'] - row['current_sla_hours'];

    if (0 < sla_diff && sla_diff <= 12 ) {
        return <MSLAStatusPlates value={SLAStatusesEnum.close_to_violation} />;
    }

    if (sla_diff > 12 ) {
        return <MSLAStatusPlates value={SLAStatusesEnum.in_range} />;
    }

    return <MSLAStatusPlates value={SLAStatusesEnum.violated} />;
}

export const InboxStudiesTable = (props: AdminMeasurementsTableProps) => {
    const { headings, rows, sx, onRowClick } = props;
    const formatMultipleValuesColumn = (i:number,value:string) => {
        return <>
            {i != 0 && <><br/><br/></>}
            {value}
        </>
    }
    const renderCell = (heading: TableHeading, row: any) => {
        switch (heading.field) {
            case "sla_left":
                return `${formatHoursToReadableFormat(row['sla_hours'] - row['current_sla_hours'])}`;
            case "sla_details":
                return `${formatHoursToReadableFormat(row['current_sla_hours'])} 
                        (${formatHoursToReadableFormat(row['sla_hours'])})`;
            case "sla_status":
                return getSLAStatusPlate(row);    
            case "status":
                return <MStatusPlates value={row[heading.field]} />
            case 'id':
                return <Link style={{ fontSize: '14px' }} to={baseUrls.clientsTools+"/study/"+row[heading.field]}>{row[heading.field]}</Link>
            case 'date_upload':
                return <>{row.measurements.map((measurement:any,i:number) =>
                    formatMultipleValuesColumn(i,measurement.date_upload?
                        new Date(row[heading.field]).toLocaleString('en-GB', {  
                            day: '2-digit',  
                            month: '2-digit',  
                            year: 'numeric',  
                            hour: '2-digit',  
                            minute: '2-digit',  
                            hour12: false  
                        }) : "--"
                    ))}</>
            case "device_serial_number":
                return <>{row.measurements.map((measurement:any,i:number) =>
                    formatMultipleValuesColumn(i,measurement.device_serial_number))}</>
            default:
                return row[heading.field];
        }
    };

    return (
        <MTable
            headings={headings} 
            rows={rows}
            renderCell={renderCell}
        />
    );
}

export default InboxStudiesTable;
