import Paper from "@mui/material/Paper";
import PageHeader from "../containers/PageHeader";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import AddEditAccountForm, { AddEditAccountHandlersFormInterface } from "../containers/AddEditAccountForm/AddEditAccountForm";
import { addAccount, addRegionClusterAccount, getAccount, getRegionClusterAccount, updateAccount, updateRegionClusterAccount } from "../api/accounts";
import {getPersonalAccount, updatePersonalAccount} from "../api/accounts";
import { createStuffAccount, getStaffProfile, updateStaffProfile } from "../api/clinic_staff";
import { clinicianRoles, unitOwnerRoles, generalAccountRoles, connectedUnitsRoles } from "../containers/AddEditAccountForm/formUtils";
import { PermissionsE, usePermissions } from "../context/usePermissions";
import { Typography } from "@mui/material";
import { NO_PERMISSIONS_MESSAGE } from "../utils/constants";
import { useAuth, isSuperAdmin } from "../context/useAuth";

const AddEditAccount = () => {
    const { hasPermission } = usePermissions();
    const { user } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const [action, setAction] = useState('');

    const searchParams = new URLSearchParams(location.search);
    const clinicId = searchParams.get('clinicId') || undefined;
    const clusterId = searchParams.get('clusterId') || undefined;
    const regionId = searchParams.get('regionId') || undefined;
    const accountId = searchParams.get('accountId') || undefined;

    useEffect(() => {
        if (location.pathname === '/add-account') {
            setAction('create');
            return;
        }

        if (location.pathname === '/edit-account') {
            setAction('edit');
            return;
        }

        if (location.pathname === '/edit-personal-account') {
            setAction('edit-own');
            return;
        }
    }, [location]);

    if (!action) return <></>;

    const getTitle = () => {
        switch(action){
            case "create":
                return "New account";
            case "edit":
                return "Edit account";
            case "edit_own":
                return "Account settings";
            default:
                return '';    
        }
    }

    const isSystemStuff = hasPermission!(PermissionsE["system__staff.r"]) || hasPermission!(PermissionsE["system__staff.w"]);

    const defaultFormValues = () => {
        if (action === "edit_own") {
            return {
                getAccountHandler: getPersonalAccount,
                addAccountHandler: undefined,
                updateAccountHandler: updatePersonalAccount,
                roles: undefined
            }
        }
        
        return isSystemStuff ? {
            getAccountHandler: getAccount,
            addAccountHandler: addAccount,
            updateAccountHandler: updateAccount,
            roles: isSuperAdmin(user) ? generalAccountRoles : connectedUnitsRoles
        } : undefined;    
    }

    const getHandlers = () => {
        if (action === "edit-own") {
            return {
                getAccountHandler: getPersonalAccount,
                addAccountHandler: undefined,
                updateAccountHandler: updatePersonalAccount,
                roles: undefined
            }
        }

        if (clinicId) {
            if (!hasPermission!(PermissionsE["units__staff.w"])) return undefined;
            return {
                getAccountHandler: getStaffProfile,
                addAccountHandler: createStuffAccount,
                updateAccountHandler: updateStaffProfile,
                roles: clinicianRoles,
                unitId: clinicId
            };
        }

        if (clusterId) {
            if (!isSystemStuff) return undefined;
            return {
                getAccountHandler: (userId: string) => getRegionClusterAccount("cluster", clusterId, userId),
                addAccountHandler: (data: any) => addRegionClusterAccount("cluster", clusterId, data),
                updateAccountHandler: (userId: string, data: any) => updateRegionClusterAccount("cluster", clusterId, userId, data),
                roles: unitOwnerRoles,
                unitId: clusterId
            };
        }

        if (regionId) {
            if (!isSystemStuff) return undefined;
            return {
                getAccountHandler: (userId: string) => getRegionClusterAccount("region", regionId, userId),
                addAccountHandler: (data: any) => addRegionClusterAccount("region", regionId, data),
                updateAccountHandler: (userId: string, data: any) => updateRegionClusterAccount("region", regionId, userId, data),
                roles: unitOwnerRoles,
                unitId: regionId
            };
        }

        return defaultFormValues();   
    }

    if (!getHandlers()) {
        return (
            <>
                <PageHeader
                    title={getTitle()}
                    actions={[{
                        onClick: () => navigate("-1"),
                        name: "Close",
                        color:"error"
                    }]}
                />
                <Paper sx={{ padding: 3 }}>
                    <Typography>{NO_PERMISSIONS_MESSAGE}</Typography>
                </Paper>
            </>
        )
    }

    return (
        <>
            <PageHeader
                title={getTitle()}
                actions={[{
                    onClick: () => navigate(-1),
                    name: "Close",
                    color:"error"
                }]}
            />
            <Paper>
                <AddEditAccountForm 
                    action={action} 
                    accountId={accountId}
                    {...getHandlers() as AddEditAccountHandlersFormInterface}
                />
            </Paper>
        </>
    );
};

export default AddEditAccount;