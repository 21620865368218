import { useState, useEffect, useCallback } from "react";
import Paper from "@mui/material/Paper";
import PageHeader, { PageHeaderActionsProps } from "../containers/PageHeader";
import { useNavigate, useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { getClinicDetail, updateClinic } from "../api/clinic";
import { getClinicStaffList } from "../api/clinic_staff"
import { Box, Button, Grid } from "@mui/material";
import AccountsGrid from "../containers/Grids/AccountsGrid";
import { baseUrls } from "../routes";
import {analysisPlatforms, featureFlagsList, languages, studiesList} from "../utils/constants";
import MCheckbox from "../components/MCheckbox";
import MTextField from "../components/MTextField";
import AdminMeasurementsGrid from "../containers/Grids/AdminMeasurementsGrid";
import { listStudiesClinicAdmin } from "../api/study";
import MSelect from "../components/MSelect";
import { listIdOrganizationExternals } from "../api/id_organization_external";
import { MBreadcrumbs } from "../components/MBreadcrumbs";
import { prepareAdminBreadcrumbs } from "../utils/utils";
import { deleteStaffProfile, updateStaffProfile } from "../api/clinic_staff";
import { PermissionsE, usePermissions } from "../context/usePermissions";
import {getValueFromLocalStorage} from "../api/auth";
import { listUnitContacts } from "../api/unit_contact";
import UnitContactsGrid from "../containers/Grids/UnitContactsGrid";

const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

export const Clinic = () => {
    const { hasPermission } = usePermissions();
    const { clinicId } = useParams();
    const navigate = useNavigate();

    const [clinicData, setClinicData] = useState<any>(null);
    const [breadcrumbsData, setBreadCrumbsData] = useState<any>(null);


    const [studies, setStudies] = useState<any[]>([]);
    const [clinicName, setClinicName] = useState("");
    const [allowedStudies, setAllowedStudies] = useState<string[]>([]);
    const [featureFlags, setFeatureFlags] = useState<string[]>([]);
    const [studyLanguage, setStudyLanguage] = useState("");
    const [analysisPlatform, setAnalysisPlatform] = useState("amps");
    const [SLAHours, setSLAHours] = useState<any>(0);
    const [SLAWorkDays, setSLAWorkDays] = useState<any>(0);

    const [idOrgExternal, setIdOrgExternal] = useState("");
    const [idOrganizationExternals, setIdOrganizationExternals] = useState([]);

    const [isEditClinic, setIsEditClinic] = useState(false);

    const [staffProfiles, setStaffProfiles] = useState([]);
    const [unitContacts, setUnitContacts] = useState([]);

    const [isLoaded, setIsLoaded] = useState(false);

    const loadContent = useCallback(async () => {
        if (clinicId) {
            await fetchClinicDetail()
            await fetchClinicContacts() 
            await fetchStaffProfilesList()
            await fetchStudies()
        }
    }, [clinicId])

    useEffect(() => {
        loadContent()
    }, [loadContent]);


    const loadBreadCrumbsData = async() => {
        const { connected_units,role } = await getValueFromLocalStorage('staff_profile');
        let data = prepareAdminBreadcrumbs(role, connected_units, region, cluster, clinic);
        setBreadCrumbsData(data);
    }

    useEffect(() => {
        loadBreadCrumbsData()
    }, [clinicData]);

    const fetchStudies = async () => {
        // setIsMeasurementAvailableMore(false);
        const studies = await listStudiesClinicAdmin(clinicId)

        let sortedDataDescending = studies.data.sort((a: any, b: any) =>
            new Date(b.date_start).getTime() - new Date(a.date_start).getTime()
        );
        setStudies(sortedDataDescending)
    }

    const fetchStaffProfilesList = async () => {
        if (clinicId != null) {

            if(hasPermission!(PermissionsE['units__staff.r'])){
                const data = await getClinicStaffList(clinicId);
                setStaffProfiles(data);
                setIsLoaded(true);
            }
            await fetchClinicDetail();
        }
    }

    const fetchClinicContacts = async () => {
        if (clinicId != null) {

            if(hasPermission!(PermissionsE['units.r_meta'])){
                const data = await listUnitContacts(clinicId);
                setUnitContacts(data);
                setIsLoaded(true);
            }
            await fetchClinicDetail();
        }
    }

    const fetchClinicDetail = async () => {
        if (clinicId != null) {
            const data = await getClinicDetail(clinicId);
            setClinicData(data);

            setClinicName(data['name']);
            setAllowedStudies(data['allowed_studies'])
            setStudyLanguage(data['study_language'])
            setAnalysisPlatform(data['analysis_platform'])
            setIdOrgExternal(data['id_organization_external'])
            setFeatureFlags(data['feature_flags'])
            setSLAHours(data['sla_hours'])
            setSLAWorkDays(data['sla_working_days'])

            const idOptions = await listIdOrganizationExternals();

            let options = idOptions.map((originalDict: any) => {
                return {
                    value: originalDict.id_organization_external,
                    label: originalDict.id_organization_external + " - " + originalDict.description,
                };
            });

            setIdOrganizationExternals(options);
        }
    }

    const addAccount = () => {
        navigate(`${baseUrls.addAccount}?clinicId=${clinicId}`)
    }

    const saveAccountChange = async () => {
        let data = {
            "name": clinicName,
            "allowed_studies": allowedStudies,
            "study_language": studyLanguage,
            "id_organization_external": idOrgExternal,
            "feature_flags" : featureFlags,
            "analysis_platform" : analysisPlatform,
            "sla_hours" : SLAHours,
            "sla_working_days" : SLAWorkDays
        }
        await updateClinic(data, clinicId)
        setIsEditClinic(false);
    }

    const toggleDay = (index: number) => {
        const newBitmask = SLAWorkDays ^ (1 << index);
        setSLAWorkDays(newBitmask);
        console.log("Bitmask Number:", newBitmask);
        console.log("Bit Representation:", newBitmask.toString(2).padStart(7, "0"));
      };

    const handleAllowedStudiesCheck = (event: any, key: string) => {
        var updatedList = [...allowedStudies];

        if (event.target.checked) {
            updatedList = [...allowedStudies, key];
        } else {
            updatedList.splice(allowedStudies.indexOf(key), 1);
        }
        setAllowedStudies(updatedList);
    };

    const handleFeatureFlagsCheck = (event: any, key: string) => {
        var updatedList = [...featureFlags];

        if (event.target.checked) {
            updatedList = [...featureFlags, key];
        } else {
            updatedList.splice(featureFlags.indexOf(key), 1);
        }
        setFeatureFlags(updatedList);
    };

    function renderEditClientButtons() {
        if (!hasPermission!(PermissionsE['units.w_meta'])){
            return <></>
        }

        if (isEditClinic) {
            return <>
                <Button color="error" onClick={() => {
                    setIsEditClinic(false);
                    getClinicDetail(clinicId);
                }
                }>
                    Cancel
                </Button>
                <Button onClick={() => {
                    setIsEditClinic(true);
                    saveAccountChange();
                }
                }>Save</Button>
            </>
        } else {
            return <>
                <Button onClick={() => setIsEditClinic(true)}>Edit</Button>
            </>
        }
    }

    const region = clinicData ? {
        pageName: clinicData.region_name,
        to: clinicData.region
    } : undefined;

    const cluster = clinicData ? {
        pageName: clinicData.cluster_name,
        to: clinicData.cluster,
        region: clinicData.region
    } : undefined;

    const clinic = clinicData ? {
        pageName: clinicData.name,
        to: clinicData.id,
        region: clinicData.region,
        cluster: clinicData.cluster
    } : undefined;

    const pageHeaderActions = [
        hasPermission!(PermissionsE["units__staff.w"]) ? {
            onClick: addAccount,
            name: "Add Staff Account"
        } : undefined
    ].filter(Boolean) as PageHeaderActionsProps[];

    return (
        <>
            <Box mt={2}>
                {breadcrumbsData && (
                    <MBreadcrumbs {...breadcrumbsData} />
                )}
            </Box>
            <PageHeader
                title={`${!clinicName ? "??" : clinicName}`}
                subTitle="clinic"
                actions={pageHeaderActions}
            />

            <Paper sx={{ minHeight: '70vh', px: 4, py: 5.5 }}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Typography variant="h4" paragraph={true}>
                            Client details  {renderEditClientButtons()}
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <MTextField
                                            label="Clinic name"
                                            value={clinicName}
                                            onChange={(e) => setClinicName(e.target.value)}
                                            disabled={!isEditClinic}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <MSelect
                                            label="Studies language"
                                            value={studyLanguage}
                                            disabled={!isEditClinic}
                                            options={languages}
                                            onChange={(e) => setStudyLanguage(e.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            {
                                hasPermission!(PermissionsE['units.r_tech']) ? (
                                    <Grid item xs={6}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <Typography variant="body1" sx={{ mt: 1 }}>
                                                    Allowed studies
                                                </Typography>
                                                {studiesList.map((item, index) => (
                                                    <>
                                                        <MCheckbox
                                                            key={index}
                                                            label={item.label}
                                                            disabled={!isEditClinic}
                                                            checked={allowedStudies.includes(item.value)}
                                                            onChange={(e) => { handleAllowedStudiesCheck(e, item.value) }}
                                                        />
                                                        <br />
                                                    </>
                                                ))}
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="body1" sx={{ mt: 1 }}>
                                                    Feature flags
                                                </Typography>
                                                {featureFlagsList.map((item, index) => (
                                                    <>
                                                        <MCheckbox
                                                            key={index}
                                                            label={item.label}
                                                            disabled={!isEditClinic}
                                                            checked={featureFlags.includes(item.value)}
                                                            onChange={(e) => { handleFeatureFlagsCheck(e, item.value) }}
                                                        />
                                                        <br />
                                                        <br />
                                                    </>
                                                ))}
                                            </Grid>
                                            <Grid item xs={12}>
                                                <MSelect
                                                    value={idOrgExternal}
                                                    disabled={!isEditClinic}
                                                    label="ID organization external"
                                                    options={idOrganizationExternals}
                                                    onChange={(e) => { setIdOrgExternal(e.target.value) }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <MSelect
                                                    label="Analysis platform"
                                                    disabled={!isEditClinic}
                                                    value={analysisPlatform}
                                                    options={analysisPlatforms}
                                                    onChange={(e) => { setAnalysisPlatform(e.target.value); }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <MTextField
                                                    label="Back-office SLA(hours)"
                                                    value={SLAHours}
                                                    type="number"
                                                    inputProps={{ min: 24, max: 100 }}                                            
                                                    onChange={(e: any) => {
                                                        let numericValue = Number(e.target.value);
                                                        if (!isNaN(numericValue)) {
                                                            if(numericValue > 99) {
                                                                numericValue =  99;
                                                            }
            
                                                          setSLAHours(numericValue);
                                                        }
                                                      }} 
                                                    disabled={!isEditClinic}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                            <Typography variant="h6" paragraph={true}>Back-office schedule(working days)</Typography>
                                                
                                                {days.map((day, index) => (
                                                    <>
                                                    <label key={index}>
                                                        <input
                                                        type="checkbox"
                                                        checked={(SLAWorkDays & (1 << index)) !== 0}
                                                        onChange={() => toggleDay(index)}
                                                        disabled={!isEditClinic}
                                                        />
                                                        {day}
                                                    </label>
                                                    <br/>
                                                    </>
                                                ))}

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ) : <></>
                            }
                        </Grid>
                    </Grid>
                    {
                        hasPermission!(PermissionsE['units.r_meta']) ? (
                            <Grid item xs={12}>
                                <UnitContactsGrid
                                    data={unitContacts}
                                    clinicId={clinicId}
                                />
                            </Grid>
                        ) : <></>
                    }
                    {
                        hasPermission!(PermissionsE['units__staff.r']) ? (
                            <Grid item xs={12}>
                                <Typography variant="h4" paragraph={true}>
                                    Staff accounts
                                </Typography>
                                <AccountsGrid
                                    data={staffProfiles}
                                    onUpdate={updateStaffProfile}
                                    onDelete={deleteStaffProfile}
                                    updateLink={`${baseUrls.editAccount}?clinicId=${clinicId}`}
                                    entity={'clinic'}
                                />
                            </Grid>
                        ) : <></>
                    }



                    <Grid item xs={12}>
                        <Typography variant="h4" paragraph={true}>
                            Latest studies
                        </Typography>
                        <AdminMeasurementsGrid data={studies} />
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
};

export default Clinic;