import { useState, useEffect } from "react";
import { Box, InputLabel, FormHelperText } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { isValidDate, pickersMigrationHack } from '../utils/utils';

type Value = Date | null;

interface MDateTimePickerProps {
  dateLabel?: string;
  timeLabel?: string;
  value?: Value;
  onChange: (date: Value) => void;
  disabled?: boolean;
  error?: boolean;
  dateErrorText?: string;
  timeErrorText?: string;
}

export const MDateAndTimePicker = (props: MDateTimePickerProps) => {
  const {
    dateLabel,
    timeLabel,
    value = null,
    onChange,
    disabled,
    error,
    dateErrorText,
    timeErrorText
  } = props;

  const extractDate = (dateTime: Value) => (dateTime ? new Date(dateTime.getFullYear(), dateTime.getMonth(), dateTime.getDate()) : null);
  const extractTime = (dateTime: Value) => (dateTime ? new Date(1970, 0, 1, dateTime.getHours(), dateTime.getMinutes()) : null);

  // Combined state for both date and time
  const [combinedDateTime, setCombinedDateTime] = useState<Value>(value);

  useEffect(() => {
    if (value !== combinedDateTime) {
      setCombinedDateTime(value); // Update local state if external value changes
    }
  }, [value]);

  const handleDateChange = (date: Value) => {
    if (date && isValidDate(date)) { // Validate the input
      const newDateTime = combinedDateTime
          ? new Date(
              date.getFullYear(),
              date.getMonth(),
              date.getDate(),
              combinedDateTime.getHours(),
              combinedDateTime.getMinutes()
          )
          : new Date(date.getFullYear(), date.getMonth(), date.getDate());
      setCombinedDateTime(newDateTime);
      onChange(newDateTime);
    } else {
      setCombinedDateTime(null); // Handle invalid input
      onChange(null);
    }
  };

  const handleTimeChange = (time: Value) => {
    let defaultYear = combinedDateTime ? combinedDateTime.getFullYear() : new Date().getFullYear();
    let defaultMonth = combinedDateTime ? combinedDateTime.getMonth() : new Date().getMonth();
    let defaultDay = combinedDateTime ? combinedDateTime.getDate() : new Date().getDay();

    if (time && isValidDate(time)) { // Validate the input
      const newDateTime = combinedDateTime
          ? new Date(
              combinedDateTime.getFullYear(),
              combinedDateTime.getMonth(),
              combinedDateTime.getDate(),
              time.getHours(),
              time.getMinutes()
          )
          : new Date(defaultYear, defaultMonth, defaultDay, time.getHours(), time.getMinutes());
      setCombinedDateTime(newDateTime);
      onChange(newDateTime);
    } else {
      setCombinedDateTime(null); // Handle invalid input
      onChange(null);
    }
  };

  const dateError = error && (combinedDateTime ? !isValidDate(extractDate(combinedDateTime)) : true);
  const timeError = error && (combinedDateTime ? !isValidDate(extractTime(combinedDateTime)) : true);

  const dateErrorTextToShow = dateError ? dateErrorText || `${dateLabel} is invalid` : '';
  const timeErrorTextToShow = timeError ? timeErrorText || `${timeLabel} is invalid` : '';

  return (
      <Box
          sx={{
            display: 'inline-flex',
            flexDirection: 'column',
            minWidth: 0,
            width: 320
          }}
      >
        <Box>
          {dateLabel && <InputLabel>{dateLabel}</InputLabel>}
          <DatePicker
              value={extractDate(combinedDateTime)}
              onChange={handleDateChange}
              disabled={disabled}
              sx={{
                width: "100%",
                ...pickersMigrationHack(error)
              }}
          />
          {dateError && <FormHelperText sx={{ color: '#d32f2f' }}>{dateErrorTextToShow}</FormHelperText>}
        </Box>
        <Box mt={2}>
          {timeLabel && <InputLabel>{timeLabel}</InputLabel>}
          <TimePicker
              value={extractTime(combinedDateTime)}
              onChange={handleTimeChange}
              disabled={disabled}
              sx={pickersMigrationHack(timeError)}
          />
          {timeError && <FormHelperText sx={{ color: '#d32f2f' }}>{timeErrorTextToShow}</FormHelperText>}
        </Box>
      </Box>
  );
};