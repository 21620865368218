import { TableHeading } from "../../components/MTables/MTable";
import MUnitContactsTable from "../../components/MTables/MUnitContactsTable";
import { PermissionsE, usePermissions } from "../../context/usePermissions";
import {formatDateToShow} from "../../utils/utils";

interface UnitContactsGridProps {
    data: any[];
    clinicId?: string;
}

export const UnitContactsGrid = (props: UnitContactsGridProps) => {
    const { hasPermission } = usePermissions();
    const canWrite = hasPermission!(PermissionsE["id_orgs_ext.w"]);
    
    const { data,clinicId} = props;

    const tableHeadings = [
        {
            fieldName: 'Email',
            field: 'email'
        },
        {
            fieldName: 'Description',
            field: 'description'
        },
        {
            fieldName: 'Urgent/Important',
            field: 'is_critical_notifications'
        },
        {
            fieldName: 'Status updates',
            field: 'is_info_notifications'
        },
        {
            fieldName: 'Reports',
            field: 'is_success_notifications'
        },
        canWrite ? {
            fieldName: 'Actions',
            field: 'actions'
        } : null
    ].filter(Boolean) as TableHeading[];

    const rows = data.map((row) => ({
        ...row
    }));

    return (
        <MUnitContactsTable
            headings={tableHeadings}
            rows={rows}
            clinicId={clinicId}
        />
    );
};

export default UnitContactsGrid;