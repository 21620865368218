import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { getRegion } from "../api/region";
import { addCluster, getCluster, getClustersList } from "../api/cluster";
import { addClinic, getClinicsList } from "../api/clinic";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import * as React from "react";
import MTextField from "../components/MTextField";
import MSelect from "../components/MSelect";
import { listIdOrganizationExternals } from "../api/id_organization_external";
import PageHeader, { PageHeaderActionsProps } from "../containers/PageHeader";
import { CircularProgress, Grid, Paper } from "@mui/material";
import UnitsListGrid from "../containers/Grids/UnitsListGrid";
import MModal from "../components/MModal";
import Region from "./Region";
import { MButton } from "../components/MButton";
import { validateRequiredField } from "../utils/validator";
import { languages, studiesList } from "../utils/constants";
import MCheckbox from "../components/MCheckbox";
import { MBreadcrumbs } from "../components/MBreadcrumbs";
import { prepareAdminBreadcrumbs } from "../utils/utils";
import { baseUrls } from "../routes";
import ClusterAccounts from "../containers/Accounts/ClusterAccounts";
import { PermissionsE, usePermissions } from "../context/usePermissions";
import {getValueFromLocalStorage} from "../api/auth";


const analysisPlatforms = [
    {
        value: 'amps',
        label: 'AMPS',
    }
]


export const Cluster = () => {
    const { hasPermission } = usePermissions();
    const canWrite = hasPermission!(PermissionsE["units.w"]);
    const [breadcrumbsData, setBreadCrumbsData] = useState<any>(null);

    const { clusterId } = useParams();
    const [clusterDetails, setClusterDetails] = useState<any>(null);

    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [clinicsList, setClinicsList] = useState<any[]>([]);

    const [newClinicName, setNewClinicName] = useState("");
    const [newClinicNameError, setNewClinicNameError] = useState<string>('');

    const [newAllowedStudies, setNewAllowedStudies] = useState<string[]>([]);
    const [newStudyLanguage, setNewStudyLanguage] = useState(languages[0].value);
    const [newAnalysisPlatform, setNewAnalysisPlatform] = useState("amps");

    const [newIdOrgExternal, setNewIdOrgExternal] = useState("");
    const [newIdOrgExternalError, setNewIdOrgExternalError] = useState<string>('');

    const [idOrganizationExternals, setIdOrganizationExternals] = useState([]);

    const [isProcessing, setIsProcessing] = useState(false);

    const fetchClusterDetails = useCallback(async () => {
        if (!hasPermission!(PermissionsE["units.r"])) return;

        if (clusterId != null) {
            const clusterDetails = await getCluster(clusterId);

            setClusterDetails(clusterDetails);

            const clinicsList = await getClinicsList(clusterId);
            clinicsList.sort((a: any, b: any) => b.departments_number - a.departments_number);

            setClinicsList(clinicsList);

            const data = await listIdOrganizationExternals();

            let options = data.map((originalDict: any) => {
                return {
                    value: originalDict.id_organization_external,
                    label: originalDict.id_organization_external + " - " + originalDict.description,
                };
            });
            setNewIdOrgExternal(options[0].value)
            setIdOrganizationExternals(options);
        }
    }, [clusterId])

    useEffect(() => {
        fetchClusterDetails()
    }, [fetchClusterDetails]);



    const loadBreadCrumbsData = async() => {
        const { connected_units,role } = await getValueFromLocalStorage('staff_profile');
        let data = prepareAdminBreadcrumbs(role, connected_units, region, cluster);
        setBreadCrumbsData(data);
    }

    useEffect(() => {
        loadBreadCrumbsData()
    }, [clusterDetails]);

    const onEntityCreation = async () => {
        if (!canWrite) return;
        const newClinicNameMessage = validateRequiredField('Clinic name', newClinicName);
        const newIdOrgExternalMessage = validateRequiredField('Id organization external', newIdOrgExternal);

        if (newClinicNameMessage || newIdOrgExternalMessage) {
            setNewIdOrgExternalError(newIdOrgExternalMessage);
            setNewClinicNameError(newClinicNameMessage);
            return;
        }

        setIsProcessing(true);

        let data = null;

        if (hasPermission!(PermissionsE["units.w_tech"])){
            data = {
                "region": clusterDetails ? clusterDetails['region'] : null,
                "cluster": clusterDetails ? clusterDetails['id'] : null,
                "name": newClinicName,
                "study_language": newStudyLanguage,
                "allowed_studies": newAllowedStudies,
                "id_organization_external": newIdOrgExternal,
                "analysis_platform": newAnalysisPlatform
            };
        } else {
            data = {
                "region": clusterDetails ? clusterDetails['region'] : null,
                "cluster": clusterDetails ? clusterDetails['id'] : null,
                "name": newClinicName,
                "study_language": newStudyLanguage
            }
        }

        await addClinic(data);
        window.location.reload();

    }

    const handleAllowedStudiesCheck = (event: any, key: string) => {
        var updatedList = [...newAllowedStudies];

        if (event.target.checked) {
            updatedList = [...newAllowedStudies, key];
        } else {
            updatedList.splice(newAllowedStudies.indexOf(key), 1);
        }
        setNewAllowedStudies(updatedList);
    };

    const formValueChangeHandler = (field: 'newClinicName' | "newIdOrgExternal",
        value: string) => {

        if (field === 'newClinicName') {
            if (newClinicNameError) {
                setNewClinicNameError('');
            }
            return setNewClinicName(value);
        }

        if (field === 'newIdOrgExternal') {
            if (newIdOrgExternalError) {
                setNewIdOrgExternalError('')
            }

            return setNewIdOrgExternal(value);
        }
    }

    const renderModalContent = () => (
        <Box p={3}>
            <Box sx={{ textAlign: 'center' }}>
                <Typography variant="h3">Add new clinic</Typography>
            </Box>
            <Box mt={1.5} width={320}>
                <MTextField
                    label="New Clinic Name"
                    onChange={(e) => formValueChangeHandler("newClinicName", e.target.value)}
                    error={!!newClinicNameError}
                    helperText={newClinicNameError}
                />
                <br />
                <br />
                <MSelect
                    label="Studies language"
                    value={newStudyLanguage}
                    options={languages}
                    onChange={(e) => { setNewStudyLanguage(e.target.value); }}
                />
                <br />
                <br />

                {hasPermission!(PermissionsE["units.w_tech"]) && (
                    <>
                        <Typography variant="body1" sx={{ mt: 1 }}>
                            Allowed studies
                        </Typography>
                        {studiesList.map((item, index) => (
                            <>
                                <MCheckbox
                                    key={index}
                                    label={item.label}
                                    checked={newAllowedStudies.includes(item.value)}
                                    onChange={(e) => { handleAllowedStudiesCheck(e, item.value) }}
                                />
                                <br />
                            </>
                        ))}
                        <br />
                        <br />
                        <MSelect
                            label="Analysis platform"
                            value={newAnalysisPlatform}
                            options={analysisPlatforms}
                            onChange={(e) => { setNewAnalysisPlatform(e.target.value); }}
                        />
                        <br />
                        <br />
                        <MSelect
                            value={newIdOrgExternal}
                            label="ID organization external"
                            error={!!newIdOrgExternalError}
                            options={idOrganizationExternals}
                            onChange={(e) => { formValueChangeHandler("newIdOrgExternal", e.target.value) }}
                        />
                    </>
                )}


            </Box>
            <Box
                mt={8}
                sx={{
                    "& .MuiButton-root:not(:last-child)": { marginRight: 2 },
                    textAlign: 'center'
                }}
            >
                <MButton onClick={handleClose} color={"inherit"}>Cancel</MButton>
                <MButton onClick={onEntityCreation} color={"primary"}>Confirm</MButton>
                {isProcessing && <CircularProgress hidden={!isProcessing} />}
            </Box>
        </Box>
    )

    const region = clusterDetails ? {
        pageName: clusterDetails.region_name,
        to: clusterDetails.region,
        region : null
    } : undefined;

    const cluster = clusterDetails ? {
        pageName: clusterDetails.name,
        to: clusterDetails.id,
        region: clusterDetails.region
    } : undefined;

    const pageHeaderActions = [
        canWrite ?                {
            onClick: handleOpen,
            name: "Add Clinic"
        } : undefined
    ].filter(Boolean) as PageHeaderActionsProps[];

    return (
        <>
            <MModal open={open} handleClose={handleClose} width={480} height="auto">
                {renderModalContent()}
            </MModal>
            <Box mt={2}>
                {breadcrumbsData && (
                    <MBreadcrumbs {...breadcrumbsData} />
                )}

            </Box>
            <PageHeader
                title={`${(!clusterDetails ? "??" : clusterDetails["name"])}`}
                subTitle="cluster"
                actions={pageHeaderActions}
            />
            <Paper sx={{ minHeight: '70vh', px: 4, py: 5.5 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Box mb={2}>
                            <Typography variant="h4" paragraph={true}>Units</Typography>
                        </Box>
                        <UnitsListGrid type="clinic" data={clinicsList} />
                    </Grid>
                </Grid>
            </Paper>
        </>
    )
}

export default Cluster;