import { networkLayer } from './networkLayer';
import { config } from "../config"


export interface ClinicInterface  {
    id : string | null;

    unit : string;

    description : string | null;

    email : string;

    is_critical_notifications : boolean;

    is_info_notifications : boolean;
    
    is_success_notifications : boolean;

}


export const addUnitContact = async (data: any, clinic_id : any) => {
    return await networkLayer('post', `${config.baseUrl}/unit_contacts/`, data, {"clinic_id":clinic_id});
}

export const listUnitContacts = async (clinic_id:any) => {
    return await networkLayer('get', `${config.baseUrl}/unit_contacts/`, null, {"clinic_id":clinic_id});
}
//
export const deleteUnitContact = async (id:any, clinic_id:any) => {
    return await networkLayer('delete', `${config.baseUrl}/unit_contacts/${id}/`, null, {"clinic_id":clinic_id});
}

export const updateUnitContact = async (id:any, data: any, clinic_id : any) => {
    return await networkLayer('patch', `${config.baseUrl}/unit_contacts/${id}/`, data, {"clinic_id":clinic_id});
}