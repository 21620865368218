import Box from '@mui/material/Box';
import { SxProps, Theme } from '@mui/system';
import { MStatusPlates } from '../MStatusPlates'
import MTable, {MTableActionsButtons} from './MTable';
import {FieldType} from "./MStudyTable";
import EditIcon from "@mui/icons-material/Edit";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import {baseUrls} from "../../routes";
import {Link} from "react-router-dom";
import {formatDateTimeToShow} from "../../utils/utils";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { green, red } from '@mui/material/colors';
import {useState} from "react";
import MModal from "../MModal";
import {Typography} from "@mui/material";
import {MButton} from "../MButton";
import MTextField from "../MTextField";
import {PermissionsE, usePermissions} from "../../context/usePermissions";
import {deleteIdOrganizationExternal, updateIdOrganizationExternal} from "../../api/id_organization_external";
import {deleteCheckpoint, updateCheckpoint} from "../../api/checkpoints";
import MTextarea from "../MTextarea";


export interface TableHeading {
    fieldName: string,
    field: string
    type?: FieldType;
}

interface MStudyTableProps {
    headings: TableHeading[];
    rows: any[];
    onRowClick?: (id: string) => void;
    sx?: SxProps<Theme>;
}



const BooleanIcon = (value : boolean | any) => {
    console.log(value);
    return value.value === true ? (
        <CheckCircleIcon style={{ color: green[500] }} />  // Green icon for true
    ) : (
        <CancelIcon style={{ color: red[500] }} />        // Red icon for false
    );
};

export const MCheckpointsTable = (props: MStudyTableProps) => {
    const { headings, rows, onRowClick } = props;
    const { hasPermission } = usePermissions();
    const canWrite = hasPermission!(PermissionsE["study.w_tech"]);

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);

    const [selectedRow,setSelectedRow] = useState<any>(null);
    const [busy, setBusy] = useState(false);

    const submitDeleteItem = async() => {
        if (!canWrite) return;
        setBusy(true);
        await deleteCheckpoint(selectedRow?.id, selectedRow.study);
        window.location.reload();
    }

    const handleItemChange = (keyToUpdate: string, newValue: string | number) => {
        let newDict = {...selectedRow};
        newDict[keyToUpdate] = newValue;
        setSelectedRow(newDict);
    };

    const submitUpdateItem = async() => {
        if (!canWrite) return;
        let data = {
            comment: selectedRow.comment,
            study: selectedRow.study
        }
        await updateCheckpoint(selectedRow.id,data, selectedRow.study);
        window.location.reload();
    }

    const saveHandler = async () => {
        if (!canWrite) return;
        setBusy(true);
        await submitUpdateItem().finally(() => setTimeout(() => setBusy(false), 500));
    }

    const renderCell = (heading: TableHeading, row: any) => {
        switch (heading.field) {
            case "id":
                return <a
                    href={`${baseUrls.technicianRawBase}/${row['study']}/${row['id']}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {row["short_id"]}
                </a>
            case "actions":
                return <MTableActionsButtons
                    rowData={row}
                    onEdit={() => {
                        setSelectedRow(row);
                        setIsEditModalOpen(true);
                        setIsEditModalOpen(true)
                    }}
                    onDelete={()=>{
                        setSelectedRow(row);
                        setIsDeleteModalOpen(true);
                    }}
                />
            case "date_created":
                return formatDateTimeToShow(row[heading.field]);
            case "is_manual":
                return <BooleanIcon value={row[heading.field]} />
            case "is_report_generated":
                return <BooleanIcon value={row[heading.field]} />
            default:
                return row[heading.field];//.toString();
        }
    };

    return (
        <div>
            <MModal
                open={isDeleteModalOpen}
                handleClose={() => {setIsDeleteModalOpen(false)}}
                width={480}
                height="auto"
            >
                <Box p={3}>
                    <Typography variant="h4" sx={{textAlign: "center", mb: 4}} paragraph={true}>
                        Checkpoint deletion
                    </Typography>

                    <Typography variant="h6" sx={{textAlign: "center", mt: 4}}>
                        Confirm deletion of "{selectedRow?.short_id}"
                    </Typography>


                    <Box sx={{textAlign: "center", mt: 4}}>
                        <MButton disabled={busy} color="error" onClick={() => setIsDeleteModalOpen(false)}>Cancel</MButton>
                        <MButton busy={busy} sx={{ml: 2}} onClick={submitDeleteItem}>Save</MButton>
                    </Box>
                </Box>
            </MModal>

            <MModal
                open={isEditModalOpen}
                handleClose={() => {setIsEditModalOpen(false)}}
                width={480}
                height="auto"
            >
                <Box p={3}>
                    <Typography variant="h4" sx={{textAlign: "center", mb: 4}} paragraph={true}>
                        Checkpoint
                    </Typography>

                    <MTextField
                        sx={{textAlign: "center", mb: 2}}
                        label="Short ID"
                        value={selectedRow?.id}
                        disabled={true}
                    />
                    <MTextarea
                        label="Comment"
                        placeholder=""
                        value={selectedRow?.comment}
                        onChange={v => handleItemChange("comment", v)}
                        maxLength={250}
                    />
                    <Box sx={{textAlign: "center", mt: 4}}>
                        <MButton disabled={busy} color="error" onClick={() => {setIsEditModalOpen(false)}}>Cancel</MButton>
                        <MButton busy={busy} sx={{ml: 2}} onClick={saveHandler}>Save</MButton>
                    </Box>
                </Box>
            </MModal>

            <MTable
                headings={headings}
                rows={rows}
                renderCell={renderCell}
                //onRowClick={onRowClick}
            />
        </div>
    );
}

export default MCheckpointsTable;
