import { createContext, useContext, useMemo, ReactElement, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { baseUrls } from "../routes";
import { useLocalStorage } from "./useLocalStorage";
import { getValueFromLocalStorage } from "../api/auth";
import { AccountRolesEnum, connectedUnitsRoles } from "../containers/AddEditAccountForm/formUtils";

export interface User {
  access: string;
  refresh: string;
  staff_profile: any;
}

interface AuthContextInterface {
  user: User | null,
  login: (data: User) => void,
  logout: () => void
}

const defaultAuthContext = {
  user: null,
  login: (data: User) => {},
  logout: () => {}
};

const AuthContext = createContext<AuthContextInterface>(defaultAuthContext);

interface AuthProviderInterface {
  children: ReactElement;
}

export const isAccountAdminByRole = (role: string) => {
  return role === AccountRolesEnum["IT Admin"] || role === AccountRolesEnum["Super Admin"];
}

export const isClinician = (user: User | null) => {
  if (!user) return;
  return user?.staff_profile?.role === AccountRolesEnum.Clinician;
}

export const isAnyTechnician = (user: User | null) => {
  if (!user) return;
  return user?.staff_profile?.role === AccountRolesEnum["Lead Technician"] ||
         user?.staff_profile?.role === AccountRolesEnum.Technician || 
         user?.staff_profile?.role === AccountRolesEnum["Senior Technician"];
}

export const isTechnician = (user: User | null) => {
  if (!user) return;
  return user?.staff_profile?.role === AccountRolesEnum.Technician;
}

export const isAdmin = (user: User | null) => {
  if (!user) return;
  return user?.staff_profile?.role === AccountRolesEnum["IT Admin"];
}

export const isAdminOrTechnician = (user: User | null) => {
  if (!user) return;
  return user?.staff_profile?.role !== AccountRolesEnum["Unit Owner"] && user?.staff_profile?.role !== AccountRolesEnum["Account Manager"];
}

export const isSuperAdmin = (user: User | null) => {
  if (!user) return;
  return user?.staff_profile?.role === AccountRolesEnum["Super Admin"];
}

export const isMCU = (user: User | null) => {
  if (!user) return;
  return !!connectedUnitsRoles.find(ur => ur.value === user?.staff_profile?.role);
}

export const AuthProvider = ({ children }: AuthProviderInterface) => {
  const [user, setUser] = useLocalStorage("user", null);

  const navigate = useNavigate();

  // call this function when you want to authenticate the user
  const login = async (data: User) => {
    setUser(data);

    if (isClinician(data)) {
      navigate(baseUrls.studies);
      return;
    }

    if (isAdmin(data) || isSuperAdmin(data)) {
      navigate(baseUrls.regions);
      return;
    }

    if(isAnyTechnician(data)){
      navigate(baseUrls.technicianInbox);
      return;
    }

    if(isMCU(data)) {
      navigate(baseUrls.mcuHome);
    }
  };

  // call this function to sign out logged in user
  const logout = () => {
    navigate(baseUrls.login, { replace: true });
    setUser(undefined);
    localStorage.removeItem('user');
  };

  const value = useMemo(
    () => ({
      user,
      login,
      logout
    }),
    [user]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};