import { useState, useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
import PageHeader, { PageHeaderActionsProps } from "../containers/PageHeader";
import Typography from "@mui/material/Typography";
import { addRegion, getRegionsList } from "../api/region";
import { MModal } from "../components/MModal";
import { MTextField } from "../components/MTextField";
import { MButton } from "../components/MButton";
import { Paper } from "@mui/material";
import UnitsListGrid from "../containers/Grids/UnitsListGrid";
import { useNavigate } from "react-router-dom";
import { PermissionsE, usePermissions } from "../context/usePermissions";

export const Regions = () => {
    const { hasPermission } = usePermissions();
    const canWrite = hasPermission!(PermissionsE["units.w"]);

    const navigate = useNavigate();
    const [busy, setBusy] = useState(false);
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [regionsList, setRegionsList] = useState<any[]>([]);
    const [newRegionName, setNewRegionName] = useState("");

    const fetchRegionsList = useCallback(async () => {
        if (!hasPermission!(PermissionsE["units.r"])) return;
        const regionsList = await getRegionsList();
        let newRegionsList = [...regionsList];
        newRegionsList.sort((a: any, b: any) => b.departments_number - a.departments_number);
        setRegionsList(newRegionsList);
    }, [])

    useEffect(() => {
        fetchRegionsList()
    }, [fetchRegionsList]);

    const onRegionCreation = async () => {
        if (!canWrite) return;
        setBusy(true);
        let data = {
            "name": newRegionName
        }
        await addRegion(data);
        window.location.reload();
    }

    const renderModalContent = () => (
        <Box p={3}>
            <Box sx={{ textAlign: 'center' }}>
                <Typography variant="h3">Add new Region</Typography>
            </Box>
            <Box>
                <MTextField
                    label="Region name"
                    onChange={(e) => setNewRegionName(e.target.value)}
                />
            </Box>
            <Box
                mt={8}
                sx={{
                    "& .MuiButton-root:not(:last-child)": { marginRight: 2 },
                    textAlign: 'center'
                }}
            >
                <MButton disabled={busy} onClick={handleClose} color={"inherit"}>Cancel</MButton>
                <MButton busy={busy} onClick={onRegionCreation} color={"primary"}>Confirm</MButton>
            </Box>
        </Box>
    )

    const pageHeaderActions = [
        canWrite ? {
            onClick: handleOpen,
            name: "Add Region"
        } : undefined
    ].filter(Boolean) as PageHeaderActionsProps[];

    return (
        <>
            <MModal open={open} handleClose={handleClose} width={480} height="auto">
                {renderModalContent()}
            </MModal>
            <PageHeader
                title="Regions"
                subTitle="high-level entities"
                actions={pageHeaderActions}
            />
            <Paper sx={{ minHeight: '70vh', px: 4, py: 5.5 }}>
                <UnitsListGrid type="region" data={regionsList} />
            </Paper>
        </>
    );
}
export default Regions;