import {Paper} from "@mui/material";
import PageHeader from "../containers/PageHeader";
import Box from "@mui/material/Box";
import {listFirmwarePackages} from "../api/device";
import {useCallback, useEffect, useState} from "react";
import FirmwarePackagesGrid from "../containers/Grids/FirmwarePackagesGrid";
import IDOrganizationExternalsGrid from "../containers/Grids/IDOrganizationExternalsGrid";
import {listIdOrganizationExternals} from "../api/id_organization_external";
import InfrastructuresGrid from "../containers/Grids/InfrastructureGrid";
import {listInfrastructures} from "../api/infrastructures";
import PermissionsGrid from "../containers/Grids/PermissionsGrid";
import { listRolePermissions, UserPermissions } from "../api/permissions";
import { PermissionsE, usePermissions } from "../context/usePermissions";

export const PortalSettings = () => {
    const { hasPermission } = usePermissions();
    const [fwPackages, setFwPackages] = useState([]);
    const [idOrganizationExternals, setIdOrganizationExternals] = useState([]);
    const [infrastructures, setInfrastructures] = useState([]);
    const [rolePermissions, setRolePermissions] = useState<UserPermissions[]>([]);

    const fetchFwPackages = useCallback(async () => {
        if (!hasPermission!(PermissionsE["fw_packages.r"])) return;
        let data: [] = await listFirmwarePackages();
        if (Array.isArray(data)) setFwPackages(data);
    }, [])

    const fetchIds = useCallback(async () => {
        if (!hasPermission!(PermissionsE["id_orgs_ext.r"])) return;
        let data: [] = await listIdOrganizationExternals();
        if (Array.isArray(data)) setIdOrganizationExternals(data);
    }, [hasPermission])

    const fetchInfrastructures = useCallback(async () => {
        if (!hasPermission!(PermissionsE["infrastructures.r"])) return;
        let data: [] = await listInfrastructures();
        if (Array.isArray(data)) setInfrastructures(data);
        // let data = await listIdOrganizationExternals();
        // setIdOrganizationExternals(data);
    }, [])

    const fetchPermissions = useCallback(async () => {
        if (!hasPermission!(PermissionsE["role_perm.r"])) return
        let data: [] = await listRolePermissions();
        if (Array.isArray(data)) setRolePermissions(data);
    }, [])

    useEffect(() => {
        fetchFwPackages();
        fetchIds();
        fetchInfrastructures();
        fetchPermissions();
    }, []);

    return (
        <>
            <PageHeader title="Settings" actions={
                []
            }/>
            <Paper sx={{minHeight: '70vh', px: 4, py: 5.5}}>
                {hasPermission!(PermissionsE["id_orgs_ext.r"]) ? (
                    <Box mb={4}>
                        <IDOrganizationExternalsGrid
                            data={idOrganizationExternals || []}
                        />
                    </Box>
                ) : <></>}
                {hasPermission!(PermissionsE["infrastructures.r"]) ? (
                    <Box mb={4}>
                        <InfrastructuresGrid
                            data={infrastructures || []}
                        />
                    </Box>
                ) : <></>}
               {hasPermission!(PermissionsE["fw_packages.r"]) ? (
                    <Box mb={4}>
                        <FirmwarePackagesGrid
                            data={fwPackages || []}
                        />
                    </Box>
                ) : <></>}
                {hasPermission!(PermissionsE["role_perm.r"]) ? (
                    <Box>
                        <PermissionsGrid data={rolePermissions || []} /> 
                    </Box>
                ) : <></>}
            </Paper>
        </>

    )

}