import {useAuth} from "../../context/useAuth";
import MModal from "../MModal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {MButton} from "../MButton";
import {TableHeading} from "../MTables/MAccountsTable";
import {useState, useEffect} from "react";
import NotebookImage from "../../images/notebook.png";
import MSelect from "../MSelect";
import {analysisPlatforms, featureFlagsList} from "../../utils/constants";
import MCheckbox from "../MCheckbox";
import { useNavigate } from 'react-router-dom';
import {CHANNELS, ELECTRODES, getDurations, getFrequencies} from "../../services/DMSSetup";
import {
    openDevice,
    closeDevice,
    readData,
    requestPort, sendBinaryCommand,
    sendRequestSNCommand,
    sendRequestTimeCommand
} from "../../services/DMSWebSerial";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CircularProgress from "@mui/material/CircularProgress";
import {
    bufferToStringSN,
    createConfigFile,
    createEnrollmentFile,
    dateToBuffer,
    parseDateFromBuffer
} from "../../utils/dms_utils";
import {SxProps, Theme} from "@mui/system";
import {getDMSConfigs} from "../../api/dms";
import {writeToBinaryFile, writeToFile} from "../../services/FileSystem";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {formatDateTimeLong, formatDateToShow} from "../../utils/utils";
import {StudyStatusesEnum, updateStudy} from "../../api/study";

const maxValueOptions = (items: any) => {
    const itemWithMaxValue = items.reduce((maxItem: any, currentItem: any) =>
        currentItem.value > maxItem.value ? currentItem : maxItem
    );
    return itemWithMaxValue.value;
}

const icons = {
    info: <CircularProgress size={24}  sx={{width: "10%"}}/>,
    success: <CheckCircleIcon sx={{width: "10%"}} color="success"/>,
    error: <ErrorOutlineIcon sx={{width: "10%"}} color="error"/>,
};

type ActionStatus = 'info' | 'success' | 'error';


const isValueInOptions = (valueToCheck: number, items: any) => {
    return items.some((item: any) => item.value === valueToCheck);
}


type ItemList = {
    label: string;
    value: string;
};

export const getLabelByValue = (value: string, list : any): string => {
    const item: ItemList = list.find( (e : ItemList) => e.value === value);
    return item ? item?.label : "";
};

interface DMSENrollmentModalProps {
    idStudy: string;
    idPatient : string;
    isOpenedProp: boolean;
    firstName: string;
    lastName: string;
    birthday: Date;
    startTime: Date;
    deviceSNStudy: string;
    gender: string;
    isNewStudy: boolean;
    onChangeModalVisibility?: (isOpen: boolean) => void;
}

interface DeviceRowProps {
    icon: React.ReactNode;
    status: ActionStatus;
    text: string;
    errorAction?: () => void;
    changeAction?: () => void;
    additionalInfo?: React.ReactNode;
}

const DeviceRow: React.FC<DeviceRowProps> = ({
                                                 icon,
                                                 status,
                                                 text,
                                                 errorAction,
                                                 changeAction,
                                                 additionalInfo,
                                             }) => (

    <>
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                mt: 2,
                width: "100%",
            }}
        >
            {icon}
            <Typography sx={{width: "80%", mr: "5%"}} variant="body2">
                {text}
            </Typography>
            {status === "error" && errorAction && (
                <MButton variant="contained" color="error" size="small" onClick={errorAction}>
                    Retry
                </MButton>
            )}
            {status === "success" && changeAction && (
                <MButton variant="contained" color="info" size="small" onClick={changeAction}>
                    Change
                </MButton>
            )}
        </Box>
        <br/>
        {additionalInfo}
    </>

);

export const DMSEnrollmentModal = (props: DMSENrollmentModalProps) => {
    const {idStudy,
        isNewStudy,
        idPatient,
        isOpenedProp,
        onChangeModalVisibility,
        firstName,
        lastName,
        birthday,
        startTime,
        deviceSNStudy,
        gender} = props;

    const navigate = useNavigate();
    const [step, setStep] = useState("initial");
    // const [isOpened, setIsOpened] = useState<boolean>(isOpenedProp);

    const [electrode, setElectrode] = useState<string>(ELECTRODES[0].value)
    const [channels, setChannels] = useState<string[]>([
        CHANNELS[0].value,
        CHANNELS[1].value,
        CHANNELS[2].value]);

    const [duration, setDuration] = useState<number>(7);
    const [frequency, setFrequency] = useState<number>(256);

    const [durationOptions, setDurationOptions] = useState(getDurations(channels));
    const [frequencyOptions, setFrequencyOptions] = useState(getFrequencies(duration, channels));

    const [directoryHandle, setDirectoryHandle] = useState<FileSystemDirectoryHandle | null>(null);
    const [directoryError, setDirectoryError] = useState<string | null>(null);

    const [dmsDevice, setDMSDevice] = useState<USBDevice | undefined>(undefined);
    const [dmsDeviceError, setDMSDeviceError] = useState<string | null>(null);

    const [deviceSN, setDeviceSN] = useState<string | null>(null);

    // Enrollment process

    // 1. Clear files
    const [isFilesCleared, setIsFilesCleared] = useState<boolean | null>(null);
    const [isFilesClearedError, setIsFilesClearedError] = useState<string | null>(null);

    // 2. Set time
    const [deviceTime, setDeviceTime] = useState<Date | null>(null);
    const [deviceTimeError, setDeviceTimeError] = useState<string | null>(null);

    // 3. Generate configuration
    const [enrollmentEncrypted, setEnrollmentEncrypted] = useState<string | null>(null);
    const [configEncrypted, setConfigEncrypted] = useState<string | null>(null);
    const [configsError, setConfigsError] = useState<string | null>(null);

    // 4. Apply configuration
    const [isConfigApplied, setIsConfigApplied] = useState<boolean | null>(null);
    const [isConfigAppliedError, setIsConfigAppliedError] = useState<string | null>(null);

    // 5. Update study
    const [isStudyUpdated, setIsStudyUpdated] = useState<boolean | null>(null);
    const [isStudyUpdatedError, setIsStudyUpdatedError] = useState<string | null>(null);

    useEffect(() => {
        if (onChangeModalVisibility) {
            onChangeModalVisibility(isOpenedProp);
        }

    }, [isOpenedProp]);

    useEffect(() => {
        setDefaultDuration();
    }, [channels]);

    useEffect(() => {
        setDefaultFrequency();
    }, [duration]);

    useEffect(() => {
        if(dmsDevice && !directoryHandle){
            handleRequestAccess();
        }
    }, [dmsDevice]);

    const handleConfigsGeneration = async () => {
        const enrollmentData = createEnrollmentFile(
            firstName,
            lastName,
            birthday,
            startTime,
            idPatient,
            (deviceSN) ? deviceSN : "",
            gender
        );

        const configData = createConfigFile(frequency, duration, channels, deviceSN);

        const payload = {
            "config_data": configData,
            "enrollment_data": enrollmentData
        }
        let data = await getDMSConfigs(payload, idStudy);

        try{
            setEnrollmentEncrypted(data.data.enrollment);
            setConfigEncrypted(data.data.config);
            await handleDeviceEnrollment(data.data.enrollment,data.data.config);
        } catch(error) {
            setConfigsError("Failed to generate config");
        }

    }

    const handleEnrollmentCancelation = () => {
        if(isNewStudy){
            navigate("/");
        }

        if (onChangeModalVisibility) {
            setStep("initial");
            setDMSDevice(undefined);
            setDirectoryHandle(null);
            setDeviceSN(null);
            setDeviceTime(null);
            setEnrollmentEncrypted(null);
            setConfigEncrypted(null);
            setIsStudyUpdated(null);
            setIsConfigApplied(null);
            onChangeModalVisibility(false);
        }

    }

    const handleSetDeviceSN = async (device: USBDevice) => {
        if (!device) {
            return;
        }

        await sendRequestSNCommand(device);
        let serial_number_binary = await readData(device, 64);
        if (serial_number_binary) {
            setDeviceSN(bufferToStringSN(serial_number_binary))
        }
    }

    const setDefaultDuration = () => {
        let durations = getDurations(channels);
        if (!isValueInOptions(duration, durations)) {
            const maxValue = maxValueOptions(durations);
            setDuration(maxValue);

        }
        setDurationOptions(durations);
    }

    const updateDuration = (durationNew: string) => {
        let durationInt = Number(durationNew);
        setDuration(durationInt);
        setDefaultFrequency();
    }
    const updateFrequency = (frequencyNew: string) => {
        let frequencyInt = Number(frequencyNew);
        setFrequency(frequencyInt);
    }


    const setDefaultFrequency = () => {
        let frequencies = getFrequencies(duration, channels);

        if (!isValueInOptions(frequency, frequencies)) {
            const maxValue = maxValueOptions(frequencies);
            setFrequency(maxValue);

        }
        setFrequencyOptions(frequencies);
    }

    const handleChannelsCheck = (event: any, key: string) => {
        var updatedList = [...channels];

        if (event.target.checked) {
            updatedList = [...channels, key];
        } else {
            updatedList.splice(channels.indexOf(key), 1);
        }
        setChannels(updatedList);

    };

    const handleDeivceConnection = async () => {
        await handleRequestSerialPortAccess();
        // await handleRequestAccess();
    }

    const handleClearFiles = async() => {
        if (!directoryHandle) {
            setIsFilesClearedError("Directory is not available");
            return;
        }

        try {
            // Request permission to access the directory
            const permission = await directoryHandle.requestPermission({ mode: "readwrite" });
            if (permission !== "granted") {
                setIsFilesClearedError("Directory is not available");

                return;
            }

            const filesToDelete = [];
            for await (const entry of directoryHandle.values()) {
                if (entry.kind === "file" && /\.(cdm|log|enc)$/i.test(entry.name)) {
                    filesToDelete.push(entry.name);
                }
            }

            const deletedFiles = [];

            // Iterate through directory entries
            for (const fileName of filesToDelete) {
                try{
                    await directoryHandle.removeEntry(fileName);
                } catch (error) {

                }

                deletedFiles.push(fileName);
            }

            if(filesToDelete.length <= deletedFiles.length){
                setIsFilesClearedError(null);
                setIsFilesCleared(true);
            } else {
                setIsFilesClearedError("Error. Please retry");
            }
        } catch (error) {
            setIsFilesClearedError((error as Error).message);
        }
    }

    const sendUpdateTimeCommand = async () => {
        if(!dmsDevice) {
            return;
        }

        await sendBinaryCommand(dmsDevice,dateToBuffer(),false);
        await new Promise(resolve => setTimeout(resolve, 1000));
    }

    const sendGetTimeCommand = async () => {
        if(!dmsDevice) {
            return;
        }

        await sendRequestTimeCommand(dmsDevice);
        //await dmsDevice.reset();
        let date_binary = await readData(dmsDevice,64);

        if(date_binary){
            date_binary = new DataView(date_binary.buffer);
            await setDeviceTime(parseDateFromBuffer(date_binary));
        }
    }

    const handleSetDeviceTime = async () => {
        if(!dmsDevice) {
            return;
        }
        await openDevice(dmsDevice);

        await sendUpdateTimeCommand();
        await sendGetTimeCommand();

        await closeDevice(dmsDevice);
    }


    const handleDeviceEnrollment = async (enrollmentEncrypted: any, configEncrypted: any) => {
        if (!enrollmentEncrypted || ! configEncrypted){
            return;
        }

        try{
            await writeToFile("myPatchEnrollment.enc",enrollmentEncrypted,directoryHandle);
            await writeToBinaryFile(".config.enc",configEncrypted,directoryHandle);
            setIsConfigApplied(true);
        } catch(error) {
            setIsConfigAppliedError((error as Error).message);
        }
    }

    const handleUpdateStudy = async () => {
        let payload = {
            "status" : StudyStatusesEnum.inprogress
        }

        let data = await updateStudy(payload,idStudy);
        setIsStudyUpdated(true);
    }

    const handleEnrollmentProcess = async () => {
        await handleClearFiles();
        await handleSetDeviceTime();
        await handleConfigsGeneration();
        await new Promise(resolve => setTimeout(resolve, 1000));
        // await handleDeviceEnrollment();
        await handleUpdateStudy();
    }


    // Device access
    const handleRequestAccess = async () => {
        try {
            const handle = await window.showDirectoryPicker(
                {
                    startIn: 'desktop'
                }
            );
            setDirectoryHandle(handle);
        } catch (error) {
            console.error('Error requesting file system access:', error);
            setDirectoryError((error as Error).message);
        }
    };

    const handleRequestSerialPortAccess = async () => {
        let deviceResponse = await requestPort();
        let device = deviceResponse[0];
        let error = deviceResponse[1];

        if (typeof error === "string") {
            setDMSDeviceError(error);
            return;
        }

        if (device instanceof USBDevice) {
            await setDMSDevice(device);

            if (device) {
                await openDevice(device);

                await new Promise(resolve => setTimeout(resolve, 1000));
                await handleSetDeviceSN(device);

                await closeDevice(device);
            }
        }
    }

    // Rendering

    const renderInitialStep = () => {
        return (
            <Box p={3} sx={{textAlign: "center"}}>
                <Typography variant="h4" sx={{textAlign: "center", mb: 5}} paragraph={true}>
                    Study created
                </Typography>
                <Typography variant="h6" sx={{mb: 5}}>
                    Do you want to enroll now?
                </Typography>
                <Box sx={{textAlign: "center", display: "flex", justifyContent: "center", mt: 5, gap: 4}}>
                    <MButton variant="outlined" onClick={() => handleEnrollmentCancelation()}>Enroll later</MButton>
                    <MButton onClick={() => setStep("start")}>Enroll now</MButton>
                </Box>
            </Box>
        )

    }

    const renderEnrollmentStart = () => {
        return (
            <Box p={3} sx={{textAlign: "center"}}>
                <Typography variant="h4" sx={{textAlign: "center", mb: 5}} paragraph={true}>
                    Enroll now
                </Typography>
                <Typography variant="body2" sx={{mb: 2}}>
                    Make sure the device is plugged into the computer and charged.
                </Typography>
                <img style={{width: '213px', height: '138px'}} src={NotebookImage} alt="Notebook image"/>
                <Typography variant="body2" sx={{mb: 5}}>
                    If it’s a first time using this computer for enrolment, make sure you have
                    {<MButton variant="text" onClick={() => setStep("drivers")}>required drivers installed.</MButton>}
                </Typography>
                <Box sx={{textAlign: "center", display: "flex", mt: 5, justifyContent: "center", gap: 4}}>
                    <MButton variant="outlined" color="error" onClick={() => handleEnrollmentCancelation()}>Cancel</MButton>
                    <MButton onClick={() => {
                        setStep("device_connection");
                        handleDeivceConnection();
                    }}>Start</MButton>
                </Box>
            </Box>
        )
    }

    const renderDriversInstallation = () => {
        return (
            <Box p={3} sx={{textAlign: "center"}}>
                <Typography variant="h4" sx={{textAlign: "center", mb: 5}} paragraph={true}>
                    Drivers Installation
                </Typography>
                <Typography variant="body2" sx={{mb: 2}}>
                    To make system work with the device you need to install drivers.
                    Please download the one appropriate for your OS and install it.
                </Typography>
                <a
                    href="https://mawi-dms-enrollment-bucket.s3.eu-west-1.amazonaws.com/mawi_windows_driver_archive.zip"
                    download="mawi_windows_driver.zip" // Allows specifying the filename for downloaded content
                    style={{
                        textDecoration: "underline", // Underline the text
                        color: "black",              // Make the text black
                    }}
                >
                    Download for Windows + Instruction (.zip)
                </a>
                <br/>
                <br/>
                <a
                    href="https://mawi-dms-enrollment-bucket.s3.eu-west-1.amazonaws.com/mawi_macos_driver.dmg"
                    download="mawi_macos_driver.dmg" // Allows specifying the filename for downloaded content
                    style={{
                        textDecoration: "underline", // Underline the text
                        color: "black",              // Make the text black
                    }}
                >
                    Download for MacOS (.dmg)
                </a>

                <Box sx={{textAlign: "center", display: "flex", mt: 5, justifyContent: "center", gap: 4}}>
                    <MButton onClick={() => {
                        setStep("device_connection");
                        handleDeivceConnection();
                        }
                    }>
                        Confirm it's done
                    </MButton>
                </Box>
            </Box>
        )
    }

    const renderConfiguration = () => {
        return (
            <Box p={3} sx={{textAlign: "left"}}>
                <Typography variant="h4" sx={{textAlign: "center", mb: 5}} paragraph={true}>
                    Configure the device
                </Typography>
                <MSelect
                    label="Electrode"
                    value={electrode}
                    options={ELECTRODES}
                    onChange={(e) => setElectrode(e.target.value)}
                />
                <Typography variant="h6" sx={{mt: 2}}>
                    Channels
                </Typography>
                {CHANNELS.map((item: any, index) => (
                    <>
                        <MCheckbox
                            key={index}
                            label={item.label}
                            checked={channels.includes(item.value)}
                            onChange={(e) => {
                                handleChannelsCheck(e, item.value)
                            }}
                        />
                        <br/>
                    </>
                ))}
                <br/>
                <MSelect
                    label="Duration"
                    value={duration.toString()}
                    options={durationOptions}
                    onChange={(e) => updateDuration(e.target.value)}
                />
                <br/>
                <br/>
                <MSelect
                    label="Frequency"
                    value={frequency.toString()}
                    options={frequencyOptions}
                    onChange={(e) => updateFrequency(e.target.value)}
                />
                <Box sx={{textAlign: "center", display: "flex", mt: 5, justifyContent: "center", gap: 4}}>
                    <MButton variant="outlined" color="error" onClick={() => handleEnrollmentCancelation()}>Cancel</MButton>
                    {/*<MButton variant="outlined"  onClick={() => setStep("device_connection")}>Back</MButton>*/}
                    <MButton onClick={() => setStep("configuration_confirmation")}>Continue</MButton>
                </Box>

            </Box>
        )

    }

    const renderConfigurationConfirmation = () => {
        const userData = [
            ["First name", firstName],
            ["Last name", lastName],
            ["Gender", gender === "M" ? "Male" : "Female"],
            ["Birthday", formatDateToShow(birthday)]
        ]

        const deviceData = [
            ["Serial number", deviceSNStudy],
            ["Duration", duration.toString() + " Days"],
            ["Electrode", getLabelByValue(electrode, ELECTRODES)],
            ["Channels", channels.sort().toString()],
            ["Sampling rate", frequency.toString() + " Hz"],
            ["Test start", formatDateTimeLong(startTime)]
        ]

        return (
            <Box p={1} sx={{textAlign: "center"}}>
                <Typography variant="h4" sx={{textAlign: "center", mb: 5}} paragraph={true}>
                    Confirm enrollment
                </Typography>
                {userData.map((item: any, index: number) => (
                        <Box sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center"
                        }}>
                            <Box sx={{width: "50%"}}>
                                <Typography variant="body2" sx={{textAlign: "right", pr: "10%"}} paragraph={true}>
                                    {item[0]}
                                </Typography>
                            </Box>
                            <Box sx={{width: "55%", pl: "5%"}}>
                                <Typography variant="body2" sx={{textAlign: "left"}} paragraph={true}>
                                    <b>{item[1]}</b>
                                </Typography>
                            </Box>
                        </Box>
                    )
                )
                }
                <br/>
                {deviceData.map((item: any, index: number) => (
                        <Box sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center"
                        }}>
                            <Box sx={{width: "50%"}}>
                                <Typography variant="body2" sx={{textAlign: "right", pr: "10%"}} paragraph={true}>
                                    {item[0]}
                                </Typography>
                            </Box>
                            <Box sx={{width: "55%", pl: "5%"}}>
                                <Typography variant="body2" sx={{textAlign: "left"}} paragraph={true}>
                                    <b>{item[1]}</b>
                                </Typography>
                            </Box>
                        </Box>
                    )
                )
                }

                <Box sx={{textAlign: "center", display: "flex", mt: 5, justifyContent: "center", gap: 4}}>
                    <MButton variant="outlined" color="error" onClick={() => handleEnrollmentCancelation()}>Cancel</MButton>
                    <MButton variant="outlined" onClick={() => setStep("configuration")}>Back</MButton>
                    <MButton onClick={() => {
                        setStep("finalization");
                        handleEnrollmentProcess();
                    }}>
                        Continue
                        </MButton>
                </Box>
            </Box>
        )

    }

    const renderCompleted = () => {
        return (
            <Box p={3} sx={{textAlign: "center", width: "100%"}}>
                <Typography variant="h4" sx={{textAlign: "center", mb: 5}} paragraph={true}>
                    Completed
                </Typography>
                <CheckCircleOutlineIcon sx={{ fontSize: 64 }} />
                <Typography variant="body2" sx={{textAlign: "center", mt: 5}} paragraph={true}>
                    Patient enrolled
                </Typography>
                <Typography variant="body2" sx={{textAlign: "center"}} paragraph={true}>
                    You can remove the device
                </Typography>
                <Box sx={{textAlign: "center", display: "flex", mt: 5, justifyContent: "center", gap: 4}}>
                    <MButton onClick={() => navigate("/")}>Done</MButton>
                </Box>
            </Box>
        )
    }

    const renderEnrollmentProcess = () => {
        // 1. Delete files
        const deleteFilesStatus: ActionStatus = isFilesCleared
            ? "success"
            : isFilesClearedError
            ? "error"
            : "info";

        const deleteFilesText = isFilesCleared
            ? "Device storage is cleared"
            : dmsDeviceError || "Clearing device storage...";

        // 2. Set time on device
        const setTimeStatus : ActionStatus = deviceTime
            ? "success"
            : deviceTimeError
            ? "error"
            : "info";

        const setTimeText = deviceTime
            ? "Device time updated"
            : deviceTimeError || "Updating device time...";

        // 3. Generate config
        const isFilesEncrypted = enrollmentEncrypted && configEncrypted;
        const generateConfigStatus : ActionStatus = isFilesEncrypted
            ? "success"
            : configsError
            ? "error"
            : "info";

        const generateConfigText = isFilesEncrypted
            ? "Configuration created"
            : configsError || "Creating configuration...";

        // 4. Enroll on device
        const deviceEnrollStatus = isConfigApplied
            ? "success"
            : isConfigAppliedError
            ? "error"
            : "info";

        const deviceEnrollText = isConfigApplied
            ? "Device enrolled"
            : isConfigAppliedError || "Enrolling device...";


        // 5. Update study status
        const studyUpdateStatus = isStudyUpdated
            ? "success"
            : isStudyUpdatedError
            ? "error"
            : "info";

        const studyUpdateText = isStudyUpdated
            ? "Study updated"
            : isStudyUpdatedError || "Updating study...";

        return (
            <Box p={3} sx={{textAlign: "left", width: "100%"}}>
                <Typography variant="h4" sx={{textAlign: "center", mb: 5}} paragraph={true}>
                    Finalization
                </Typography>
                <Box
                    sx={{
                        //display: "flex",
                        alignItems: "center",
                        gap: 2
                        // justifyContent: "space-between"
                    }}
                >
                    <DeviceRow
                        icon={icons[deleteFilesStatus]}
                        status={deleteFilesStatus}
                        text={deleteFilesText}
                        errorAction={handleClearFiles}
                        changeAction={undefined}
                    />
                    <DeviceRow
                        icon={icons[setTimeStatus]}
                        status={setTimeStatus}
                        text={setTimeText}
                        errorAction={handleSetDeviceTime}
                        changeAction={undefined}
                    />
                    {deviceTime && (
                        <Typography sx={{width: "80%", ml: "13%", mr: "3%"}} variant="body2">
                            <b>Device time: {deviceTime.toLocaleString()}</b>
                        </Typography>
                    )}
                    <DeviceRow
                        icon={icons[generateConfigStatus]}
                        status={generateConfigStatus}
                        text={generateConfigText}
                        errorAction={handleConfigsGeneration}
                        changeAction={undefined}
                    />
                    <DeviceRow
                        icon={icons[deviceEnrollStatus]}
                        status={deviceEnrollStatus}
                        text={deviceEnrollText}
                        errorAction={() => handleDeviceEnrollment(enrollmentEncrypted, configEncrypted)}
                        changeAction={undefined}
                    />
                    <DeviceRow
                        icon={icons[studyUpdateStatus]}
                        status={studyUpdateStatus}
                        text={studyUpdateText}
                        errorAction={handleUpdateStudy}
                        changeAction={undefined}
                    />
                </Box>
            </Box>
        )
    }


    const renderEnrollmentFinalStep = () => {
        if (isStudyUpdated) {
            return renderCompleted()
        }
        return renderEnrollmentProcess();
    }

    const renderDeviceConnection = () => {
        const deviceUSBStatus: ActionStatus = dmsDevice && deviceSN
            ? "success"
            : dmsDeviceError
                ? "error"
                : "info";
        const deviceUSBText = dmsDevice
            ? "Connected via USB"
            : dmsDeviceError || "Connecting via USB...";

        const deviceStorageStatus: ActionStatus = directoryHandle
            ? "success"
            : directoryError
                ? "error"
                : "info";
        const deviceStorageText = directoryHandle
            ? "Connected to the device storage"
            : directoryError || "Accessing device storage...";

        return (
            <Box p={3} sx={{textAlign: "left", width: "100%"}}>
                <Typography variant="h4" sx={{textAlign: "center", mb: 5}} paragraph={true}>
                    Device connection
                </Typography>

                <DeviceRow
                    icon={icons[deviceUSBStatus]}
                    status={deviceUSBStatus}
                    text={deviceUSBText}
                    errorAction={handleRequestSerialPortAccess}
                    changeAction={undefined}
                />

                {deviceSN && (
                    <Typography sx={{width: "80%", ml: "13%", mr: "3%"}} variant="body2">
                        <b>Device SN: {deviceSN}</b>
                    </Typography>
                )}

                {deviceSN && deviceSN !== deviceSNStudy && (
                    <Typography
                        sx={{width: "80%", ml: "13%", mr: "3%", color: "red"}}
                        variant="body2"
                    >
                        Device SN(Study): {deviceSNStudy}
                    </Typography>
                )}

                <DeviceRow
                    icon={icons[deviceStorageStatus]}
                    status={deviceStorageStatus}
                    text={deviceStorageText}
                    errorAction={handleRequestAccess}
                    changeAction={undefined}
                    additionalInfo={
                        directoryHandle && (
                            <Typography sx={{width: "80%", ml: "13%"}} variant="body2">
                                <b>Storage: {directoryHandle.name}</b>
                            </Typography>
                        )
                    }
                />

                <Box sx={{textAlign: "center", display: "flex", mt: 5, justifyContent: "center", gap: 4}}>
                    <MButton variant="outlined" color="error" onClick={() => handleEnrollmentCancelation()}>
                        Cancel
                    </MButton>
                    <MButton
                        onClick={() => setStep("configuration")}
                        disabled={!dmsDevice || !directoryHandle || !deviceSN}
                    >
                        Continue
                    </MButton>
                </Box>
            </Box>
        );
    }

    const renderModal = () => {
        switch (step) {
            case "initial":
                return renderInitialStep()
            case "start":
                return renderEnrollmentStart()
            case "configuration":
                return renderConfiguration()
            case "configuration_confirmation":
                return renderConfigurationConfirmation()
            case "drivers":
                return renderDriversInstallation()
            case "device_connection":
                return renderDeviceConnection()
            case "finalization":
                return renderEnrollmentFinalStep()

            default:
                return <p></p>
        }
    }


    return (
        <>
            <MModal
                open={isOpenedProp}
                handleClose={() => {

                }}
                width={560}
                height="auto"
            >
                {renderModal()}
            </MModal>
        </>
    )
}
