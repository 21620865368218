import { useCallback, useEffect, useState } from 'react';
import Box from "@mui/material/Box";
import { MTextField } from "../../components/MTextField";
import { MSelect } from "../../components/MSelect";
import FormSection from "../FormSection";
import { MButton } from "../../components/MButton";
import { useNavigate } from "react-router-dom";
import { validateEmail, validateRequiredField } from "../../utils/validator";
import * as React from "react";
import { InputAdornment, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { AccountRole, AccountRolesEnum, connectedUnitsRoles } from './formUtils';
import PDFModal from './PDFModal';
import AccessTree3 from './AccessTree/AccessTree3';
import { Account, Unit } from '../../api/accounts';
import { isAccountAdminByRole, useAuth, isSuperAdmin } from '../../context/useAuth';
import { baseUrls } from '../../routes';
import MCheckbox from '../../components/MCheckbox';

const getRandomNumberInRange = (min: number, max: number): number => {
    const randomBuffer = new Uint32Array(1);
    window.crypto.getRandomValues(randomBuffer);
    const randomNumber = randomBuffer[0] / (0xFFFFFFFF + 1); 
    
    return Math.floor(randomNumber * (max - min + 1)) + min;
  };

interface PasswordValidation {
    isLongEnough: boolean;
    hasUpperCase: boolean;
    hasLowerCase: boolean;
    hasNumber: boolean;
    // hasSpecialChar: boolean;
}

export interface AddEditAccountHandlersFormInterface {
    getAccountHandler: (id: string) => Promise<Account>;
    addAccountHandler: (data: any) => Promise<Account>;
    updateAccountHandler: (id: string, data: any) => Promise<Account>;
    roles: AccountRole[];
    unitId?: string;
}

interface AddEditAccountFormInterface extends AddEditAccountHandlersFormInterface {
    action: string;
    accountId?: string;
}

export const AddEditAccountForm = (props: AddEditAccountFormInterface) => {
    const { user } = useAuth();

    const {
        action,
        accountId = user?.staff_profile?.id,
        getAccountHandler,
        addAccountHandler,
        updateAccountHandler,
        roles,
        unitId
    } = props;

    const isEdit = action === 'edit' || action === 'edit-own';

    const navigate = useNavigate();
    const [busy, setBusy] = useState(false);

    const [isPopUpOpen, setIsPopUpOpen] = React.useState(false);

    // Credentials section
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState<string>('');

    // Notifications section
    const [isCriticalNotifications, setIsCriticalNotifications] = useState(false);
    const [isInfoNotifications, setIsInfoNotifications] = useState(false);
    const [isSuccessNotifications, setIsSuccessNotifications] = useState(false);
    const [isNotificationsChanged, setIsNotificationsChanged] = useState(false);

    const [password, setPassword] = useState<string>('');
    const [passwordError, setPasswordError] = useState<string>('');
    const [showPassword, setShowPassword] = React.useState(false);
    const [passwordValidation, setPasswordValidation] = useState<PasswordValidation>({
        isLongEnough: false,
        hasUpperCase: false,
        hasLowerCase: false,
        hasNumber: false,
        // hasSpecialChar: false,
    });

    const checkPasswordStrength = (password: string) => {
        const validation: PasswordValidation = {
            isLongEnough: password.length >= 10,
            hasUpperCase: /[A-Z]/.test(password),
            hasLowerCase: /[a-z]/.test(password),
            hasNumber: /\d/.test(password),
            // hasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
        };

        setPasswordValidation(validation);
    };

    const handlePasswordChange = (newPassword: string) => {
        setPassword(newPassword);
        checkPasswordStrength(newPassword);
    };

    const shuffleString = (str: string) => {
        const array = str.split('');
        const cryptoArray = new Uint32Array(array.length);
        window.crypto.getRandomValues(cryptoArray);

        for (let i = array.length - 1; i > 0; i--) {
            const j = cryptoArray[i] % (i + 1);
            [array[i], array[j]] = [array[j], array[i]];
        }

        return array.join('');
    };

    const generateRandomPassword = () => {
        const upperCaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const lowerCaseLetters = 'abcdefghijklmnopqrstuvwxyz';
        const numbers = '0123456789';
        // const specialCharacters = '!@#$%^&*(),.?":{}|<>';

        // Function to pick a random character from a string
        const pickRandom = (str: string) => {
            const array = new Uint32Array(1);
            window.crypto.getRandomValues(array);
            return str[array[0] % str.length];
        };

        // Ensure the password meets all criteria
        let newPassword = '';
        newPassword += pickRandom(upperCaseLetters);
        newPassword += pickRandom(lowerCaseLetters);
        newPassword += pickRandom(numbers);
        // newPassword += pickRandom(specialCharacters);

        // Fill the remaining characters to meet the minimum length from 10 to 16
        // const allCharacters = upperCaseLetters + lowerCaseLetters + numbers + specialCharacters;
        const allCharacters = upperCaseLetters + lowerCaseLetters + numbers;
        for (let i = newPassword.length; i < getRandomNumberInRange(10, 16); i++) {
            newPassword += pickRandom(allCharacters);
        }

        // Shuffle the password to ensure randomness
        setPassword(shuffleString(newPassword));
        checkPasswordStrength(newPassword);
        setShowPassword(true);
    };

    const isStrongPassword = Object.values(passwordValidation).every((value) => value);

    const [isPasswordChanged, setIsPasswordChanged] = React.useState(false);
    const [isEmailChanged, setIsEmailChanged] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event: any) => { event.preventDefault(); };

    // Account info section
    const [accountName, setAccountName] = useState<string>("");
    const [accountNameError, setAccountNameError] = useState<string>('');

    // const [role, setRole] = useState<AccountRole | null>(roles[0])
    const [role, setRole] = useState<AccountRole | null>(
        roles?.[0] ?? user?.staff_profile?.role ?? null
    );

    const [isAccountNameChanged, setIsAccountNameChanged] = React.useState(false);
    const [isRoleChanged, setIsRoleChanged] = React.useState(false);
    const [connectedUnitIds, setConnectedUnitIds] = useState<string[]>([]);

    const [connectedUnits, setConnectedUnits] = useState<Unit[]>([]);

    const getClientAccount = useCallback(async () => {
        if (isEdit && accountId) {
            let { email, 
                name, 
                role, 
                is_critical_notifications, 
                is_success_notifications, 
                is_info_notifications, 
                units = [] } = await getAccountHandler(accountId);

            if (isAccountAdminByRole(role) && !isSuperAdmin(user) && action !== "edit-own") {
                navigate(baseUrls.accounts);
                return;
            }

            const currentRole = roles?.find(r => r.value === role) || user?.staff_profile?.role;
            
            setEmail(email);
            setPassword("");
            setAccountName(name);
            setRole(currentRole);
            setConnectedUnitIds(units.map(i => i.id))
            setConnectedUnits(units);
            setIsCriticalNotifications(is_critical_notifications ?? false);
            setIsInfoNotifications(is_info_notifications ?? false);
            setIsSuccessNotifications(is_success_notifications ?? false);
        }
    }, []);

    useEffect(() => {
        if (unitId) setConnectedUnitIds([unitId]);
    }, [unitId])

    useEffect(() => {
        getClientAccount();
    }, []);

    const getConnectedUnitsIds = (ids: string[]) => {
        setConnectedUnitIds(ids);
    }

    const formValueChangeHandler = (field: 'email' | 'password' | "accountName" | "isCriticalNotifications" |
        "isSuccessNotifications" |  "isInfoNotifications",
        value: string) => {
        if (field === 'email') {
            if (emailError) {
                setEmailError('')
            }
            setIsEmailChanged(true);
            return setEmail(value);
        }

        if (field === 'password') {
            if (isStrongPassword) {
                setPasswordError('')
            }
            setIsPasswordChanged(true);
            return handlePasswordChange(value);
        }
        if (field === 'accountName') {
            if (accountNameError) {
                setAccountNameError('')
            }
            setIsAccountNameChanged(true);
            return setAccountName(value);
        }

        if (field === 'isCriticalNotifications') {
            setIsCriticalNotifications(!isCriticalNotifications)
            setIsNotificationsChanged(true);
        }

        if (field === 'isSuccessNotifications') {
            setIsSuccessNotifications(!isSuccessNotifications)
            setIsNotificationsChanged(true);
        }

        if (field === 'isInfoNotifications') {
            setIsInfoNotifications(!isInfoNotifications)
            setIsNotificationsChanged(true);
        }
    }

    const showConnectedSection = () => {
        if (connectedUnitsRoles.find(i => i.value === role?.value)) {
            return true;
        }

        return false;
    }

    const prepareAccountData = () => {
        return {
            email: email,
            password: password,
            staffprofile_set:
            {
                name: accountName,
                role: role?.value,
                stafforganizationconnection_set: connectedUnitIds || []
            }
        }
    }

    const prepareUpdateData = (action: string) => {
        switch (action) {
            case "change_email":
                return {
                    new_email: email,
                    action: action
                }
            case "change_password":
                return {
                    new_password: password,
                    action: action
                }
            case "change_account_info":
                return {
                    action: "change_meta",
                    name: accountName,
                }
            case "change_role":
                return {
                    action: "change_role",
                    new_role: role?.value || ''
                }
            
            case "change_notifications":
                return {
                    action: "change_meta",
                    is_critical_notifications: isCriticalNotifications,
                    is_success_notifications: isSuccessNotifications,
                    is_info_notifications: isInfoNotifications
                }
        }
    }

    const onSubmitCreate = async () => {
        const emailMessage = validateEmail(email);
        const accountNameMessage = validateRequiredField('Account Name', accountName);
        const passwordMessage = validateRequiredField('Password', password);

        if (emailMessage || passwordMessage || accountNameMessage) {
            setEmailError(emailMessage);
            setPasswordError(passwordMessage);
            setAccountNameError(accountNameMessage);
            return;
        }
        setBusy(true);

        let data = prepareAccountData();
        await addAccountHandler(data)
            .catch(() => {
                setTimeout(() => setBusy(false), 500)
            })
            .then((data) => {
                if (data) setIsPopUpOpen(true);
            })
            .finally(() => setTimeout(() => setBusy(false), 500));
    }

    const onSubmitEmailUpdate = async () => {
        const emailMessage = validateEmail(email);

        if (emailMessage) {
            setEmailError(emailMessage);
            return;
        }
        setBusy(true);

        let data = prepareUpdateData("change_email");
        if (accountId) {
            await updateAccountHandler(accountId, data).finally(() => setTimeout(() => setBusy(false), 500));
        }
        setIsEmailChanged(false);
    }

    const onSubmitPasswordChange = async () => {
        const passwordMessage = validateRequiredField('Password', password);
        if (passwordMessage) {
            setPasswordError(passwordMessage);
            return;
        }
        setBusy(true);

        let data = prepareUpdateData("change_password");
        if (accountId) {
            await updateAccountHandler(accountId, data).finally(() => setTimeout(() => setBusy(false), 500));
        }
        setIsPasswordChanged(false);
    }

    const accNameUpdate = async () => {
        const accountNameMessage = validateRequiredField('Account Name', accountName);
        if (accountNameMessage) {
            setAccountNameError(accountNameMessage);
            return;
        }
        setBusy(true);
        let data = prepareUpdateData("change_account_info");
        if (accountId) {
            await updateAccountHandler(accountId, data).finally(() => setTimeout(() => setBusy(false), 500));
        }
        setIsAccountNameChanged(false);
    }

    const notificationsUpdate = async () => {
        setBusy(true);
        let data = prepareUpdateData("change_notifications");
        if (accountId) {
            await updateAccountHandler(accountId, data).finally(() => setTimeout(() => setBusy(false), 500));
        }
        setIsNotificationsChanged(false);
    }

    const roleUpdate = async () => {
        setBusy(true);
        let data = prepareUpdateData("change_role");
        if (accountId) {
            await updateAccountHandler(accountId, data).finally(() => setTimeout(() => setBusy(false), 500));
        }
        setIsRoleChanged(false);
    }

    const onSubmitAccountInfoChange = async () => {
        // update acc name
        if (isAccountNameChanged) await accNameUpdate();
        // role update
        if (isRoleChanged) await roleUpdate();
    }

    return (
        <>
            <Box sx={{ maxWidth: 750, margin: "0 auto" }}>
                <FormSection sectionName="Credentials">
                    <>
                        <MTextField
                            label="Email"
                            value={email}
                            error={!!emailError}
                            helperText={emailError}
                            onChange={(e) => { formValueChangeHandler('email', e.target.value.toLowerCase()) }}
                        />
                        {isEmailChanged && isEdit &&
                            <MButton
                                busy={busy}
                                variant="outlined"
                                color="info"
                                onClick={() => onSubmitEmailUpdate()}
                                sx={{ color: "error", width: "75%" }}
                            >
                                Update email
                            </MButton>
                        }
                        <Box mb={2}>
                            <MTextField
                                label="New Password"
                                value={password}
                                type={(showPassword) ? "text" : "password"}
                                error={!!passwordError}
                                helperText={passwordError}
                                autoComplete="new-password"
                                name="new-password-field" // Using a unique/random name to avoid autofill
                                onChange={(e) => { formValueChangeHandler('password', e.target.value) }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <Box>
                                <MButton onClick={generateRandomPassword}>Generate strong password</MButton>
                                {/* <Box mt={1}>
                                    <Typography sx={{ fontSize: 12, color: 'grey' }}>Please, copy and save generated password</Typography>
                                </Box> */}
                            </Box>
                            {
                                isPasswordChanged && (
                                    <>
                                        <div style={{ textAlign: 'left', marginTop: '10px' }}>
                                            <p>
                                                <span style={{ color: passwordValidation.isLongEnough ? 'green' : 'red' }}>
                                                    {passwordValidation.isLongEnough ? '✔' : '✖'} At least 10 characters
                                                </span>
                                            </p>
                                            <p>
                                                <span style={{ color: passwordValidation.hasUpperCase ? 'green' : 'red' }}>
                                                    {passwordValidation.hasUpperCase ? '✔' : '✖'} At least one uppercase letter
                                                </span>
                                            </p>
                                            <p>
                                                <span style={{ color: passwordValidation.hasLowerCase ? 'green' : 'red' }}>
                                                    {passwordValidation.hasLowerCase ? '✔' : '✖'} At least one lowercase letter
                                                </span>
                                            </p>
                                            <p>
                                                <span style={{ color: passwordValidation.hasNumber ? 'green' : 'red' }}>
                                                    {passwordValidation.hasNumber ? '✔' : '✖'} At least one number
                                                </span>
                                            </p>
                                            {/* <p>
                                                <span style={{ color: passwordValidation.hasSpecialChar ? 'green' : 'red' }}>
                                                    {passwordValidation.hasSpecialChar ? '✔' : '✖'} At least one special character
                                                </span>
                                            </p> */}
                                        </div>
                                        <div style={{ marginTop: '10px' }}>
                                            <strong>Password Strength: </strong>
                                            <span style={{ color: isStrongPassword ? 'green' : 'red' }}>
                                                {isStrongPassword ? 'Strong' : 'Weak'}
                                            </span>
                                        </div>
                                    </>
                                )
                            }
                        </Box>
                        {isStrongPassword && isEdit &&
                            <MButton
                                busy={busy}
                                variant="outlined"
                                color="info"
                                onClick={() => onSubmitPasswordChange()}
                                sx={{ color: "error", width: "75%" }}
                            >
                                Update password
                            </MButton>
                        }
                    </>
                </FormSection>
                <FormSection sectionName="Account info">
                    <>
                        <MTextField
                            label="Account Name"
                            value={accountName}
                            error={!!accountNameError}
                            helperText={accountNameError}
                            onChange={(e) => formValueChangeHandler("accountName", e.target.value)}
                        />
                        {action !== "edit-own" && 
                            (<MSelect
                            label="Role"
                            value={role?.value}
                            options={roles? roles : []}
                            disabled={roles?.length <= 1}
                            onChange={(e) => {
                                const currentRole = roles.find(r => r.value === e.target.value) || null;

                                setRole(currentRole);
                                setIsRoleChanged(true);
                            }}
                        />)
                        }

                        {(!role || role.value !== "clinician") && user?.staff_profile?.role !== "clinician" && (
                            <>
                            <br/>
                            <br/>
                            <Typography variant='body1'>Account role</Typography>
                            <br/>
                            <Typography variant='h4'>{user?.staff_profile?.role ?? "?"}</Typography>
                            </>

                        )
                                                
                        }
                        
                        {(isAccountNameChanged || isRoleChanged) && (action === "edit" || action === "edit-own")  &&
                            <MButton
                                busy={busy}
                                variant="outlined"
                                color="info"
                                onClick={() => onSubmitAccountInfoChange()}
                                sx={{ color: "error", width: "75%", mt: "5%"}}
                            >
                                {action === "edit-own" ? "Update account name" : "Update account info" }
                            </MButton>
                        }
                    </>
                </FormSection>
                { (!role || role.value !== "clinician") && user?.staff_profile?.role !== "clinician"  && (
                    <FormSection sectionName="Notifications settings">
                        <>
                        <MCheckbox
                            label="Critical notifications(High severity report, study deletion, SLA violation)"
                            // disabled={edit}
                            checked={isCriticalNotifications}
                            onChange={(e:any,checked:any) => formValueChangeHandler("isCriticalNotifications", e.target.value)}
                        />
                        <br/>
                        <br/>
                        <MCheckbox
                            label="Info notifications(Status updates, study deletion)"
                            // disabled={edit}
                            checked={isInfoNotifications}
                            onChange={(e:any,checked:any) => formValueChangeHandler("isInfoNotifications", e.target.value)}
                        />
                        <br/>
                        <br/>
                        <MCheckbox
                            label="Success notifications(Reports)"
                            // disabled={edit}
                            checked={isSuccessNotifications}
                            onChange={(e:any,checked:any) => formValueChangeHandler("isSuccessNotifications", e.target.value)}
                        />
                        {isNotificationsChanged && (action === "edit" || action === "edit-own")  &&
                            
                            <MButton
                                busy={busy}
                                variant="outlined"
                                color="info"
                                onClick={() => notificationsUpdate()}
                                sx={{ color: "error", width: "75%" }}
                            >
                                Update notifications preferences
                            </MButton>
                        }
                        </>
                        
                </FormSection>
                )

                }
                
                {showConnectedSection() && (
                    <FormSection sectionName="Connected units">
                        <>
                            <AccessTree3
                                isEdit={isEdit}
                                connectedUnitIds={connectedUnitIds}
                                connectedUnits={connectedUnits}
                                handleSelectedItems={getConnectedUnitsIds}
                            />
                        </>
                    </FormSection>
                )}
                {action == "create" &&
                    <Box
                        py={3.5}
                        textAlign="center"
                        display="flex"
                    >
                        <Box marginLeft="45%">
                            <MButton
                                disabled={busy}
                                variant="outlined"
                                color="error"
                                onClick={() => navigate(-1)}
                                sx={{ color: "error" }}
                            >
                                Cancel
                            </MButton>
                        </Box>
                        <Box marginLeft="1%">
                            <MButton onClick={onSubmitCreate} busy={busy}>Create account</MButton>
                        </Box>
                    </Box>
                }
            </Box>
            <PDFModal
                open={isPopUpOpen}
                name={name}
                accountName={accountName}
                email={email}
                password={password}
            />
        </>
    );
};

export default AddEditAccountForm;